import { Component, TemplateRef, ViewChild, computed, input, output } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from 'tuula-common';

@Component({
  selector: 'app-collapsable-button',
  templateUrl: './collapsable-button.component.html',
  styleUrl: './collapsable-button.component.scss',
  standalone:true,
  imports: [MatIconModule, TranslateModule, MatTooltipModule, MatBadgeModule, TooltipDirective],
})
export class CollapsableButtonComponent {
  @ViewChild('actionTemplate', {static : true}) template: TemplateRef<any>;

  readonly name = input<string>('');
  readonly icon = input<string>('');
  readonly tooltip = input<string>('');
  readonly disabled = input<boolean>(false);
  readonly cssClass = input<string>('btn-round-icon-borderless');
  readonly data = input<any>(undefined);
  readonly badge = input<string>(null);

  iconFullName = computed(() => {
    if (!this.icon()) {
      return null;
    }
    return this.icon()?.startsWith('ai-icon') ? this.icon() : 'ai-icon-' + this.icon();
  });

  click = output();
  overflow: boolean = false;

  constructor() { }

  handleClick() {
    this.click.emit(this.data());
  }

  setOverflow(val: boolean) {
    this.overflow = val;
  }

}
