import {
  Component,
  Input,
  Optional,
  Output,
  EventEmitter,
  OnInit,
  SkipSelf,
  Host,
  ViewEncapsulation,
  signal,
  input,
} from '@angular/core';
import {
  DATE_REGEX,
  APP_DATE_FORMATS_ND,
  DEFAULT_MIN_DATE,
  DEFAULT_MAX_DATE,
} from '../../constants/form-controls.const';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import {
  MAT_DATE_FORMATS,
} from '@angular/material/core';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import { DateUtils } from '../../utilities';

@Component({
  selector: 'app-nd-form-input-date',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatDatepickerModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    ValidationMessageComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './nd-form-input-date.component.html',
  styleUrls: ['./nd-form-input-date.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS_ND }],
})
export class FormInputDateNdComponent implements OnInit {
  readonly minDate = input(DEFAULT_MIN_DATE);
  readonly maxDate = input(DEFAULT_MAX_DATE);
  readonly label = input<string>('');
  readonly placeholder = input<string>('');
  readonly hint = input<string>('');
  readonly testId = input.required<string, string>({
    transform: (value: string) => {
      return 'app-form-input-date-' + value;
    },
  });
  readonly disabled = input<boolean>(false);
  readonly controlName = input<string>();
  @Input() control: AbstractControl | null;

  required = signal<boolean>(false);
  dateFilter = input<Function>((d: Date | null): boolean => true);

  @Output() datepickerOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() datepickerClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeDate: EventEmitter<void> = new EventEmitter<void>();

  dateRegex = DATE_REGEX;
  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit(): void {
    const controlName = this.controlName();
    if (!this.control && controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        controlName,
      );
    }
    if (this.control) {
      this.setDefaultValues();

      if (this.disabled()) {
        this.control.disable();
      }
    }
  }

  onDatepickerOpened(): void {
    this.datepickerOpened.emit();
  }

  onDatepickerClosed(): void {
    this.datepickerClosed.emit();
  }
  /** When the user double clicks on the input field */
  setNow() {
    this.control?.setValue(DateUtils.getCurrentUTCDayMoment());
    this.datepickerClosed.emit();
    this.changeDate.emit();
  }
  /** When date is picked from the date picker or manually entered
   */
  onChangeDate() {
    this.changeDate.emit();
  }

  /** When date is entered manually
   */
  valueChangeHandler(event$: Event) {
    const date = (event$?.target as HTMLInputElement).value;
    if (!date) return;
    if (!DateUtils.isValidDate(date)) {
      this.control?.setErrors({ matDatepickerParse: true });
    }
  }

  fixRequiredError(event$: Event) {
    const date = (event$?.target as HTMLInputElement).value;
    if (date) {
      const errors = this.control.errors || {};
      delete errors['required'];
      this.control.setErrors(Object.keys(errors).length ? errors : null);
    }
  }

  get minDateValue() {
    return this.minDate() ?? DEFAULT_MIN_DATE;
  }

  get maxDateValue() {
    return this.maxDate() ?? DEFAULT_MAX_DATE;
  }

  setDefaultValues() {
    this.required.set(this.control?.hasValidator(Validators.required)!);
  }
}
