import { TemplateRef } from '@angular/core';
import { TableColumn } from './table-columns.interface';

export const ROW_ACTION_BUTTON_WIDTH = 30;

export class TableUtils {

  public static reorderPermanentColumns(cols: TableColumn[]) {
    const leftMostCols = [];
    const centerCols = [];
    const rightMostCols = [];

    cols.forEach(column => {
      const col = { ...column };
      if (col.permanent && (col.frozenRight === true || col.frozenLeft === true) && col.hideOnSettings === true) {
        if (col.frozenRight === true) {
          rightMostCols.push(col);
        } else if (col.frozenLeft === true) {
          leftMostCols.push(col);
        }
      } else {
        centerCols.push(col);
      }
    });

    return { leftMostCols, centerCols, rightMostCols };
  }

  public static calculateRowActionColumnWidths(numberOfVisibleButtons: number) {
    const minWidth = ROW_ACTION_BUTTON_WIDTH + 16;
    let width = (numberOfVisibleButtons + 1) * ROW_ACTION_BUTTON_WIDTH;
    width = width < minWidth ? minWidth : width;
    return {width, minWidth}
  }
    
  public static createRowActionColumn(cellTemplate: TemplateRef<any>, numberOfVisibleButtons: number = 3): TableColumn {
    const widths = this.calculateRowActionColumnWidths(numberOfVisibleButtons);
    
    return {
      name: '',
      prop: 'menuActions',
      cellTemplate: cellTemplate,
      frozenRight: true,
      permanent: true,
      hideOnSettings: true,
      resizeable: false,
      draggable: false,
      sortable: false,
      justify: 'end',
      width: widths.width,
      minWidth: widths.minWidth,
      settingsSavable: false
    }
  }

}
