import { inject } from '@angular/core';
import {
  ActionType,
  SIDE_SHEET_CONFIG,
  SIDE_SHEET_CONTENT,
  SideSheetButtonEvent,
  SideSheetButtonListener,
} from '../../../models';
import { SideSheetService } from '../../../services';

export abstract class AbstractSideSheetComponent
  implements SideSheetButtonListener
{

  protected sideSheetService=inject(SideSheetService);
  protected sideSheetContent=inject(SIDE_SHEET_CONTENT,{optional:true});
  protected sideSheetConfig=inject(SIDE_SHEET_CONFIG,{optional:true});
  protected constructor(
  ) {}


  protected closeSideSheetImmediately(event: SideSheetButtonEvent) {
    if (event?.button?.actionType === ActionType.SIDE_SHEET_CLOSE) {
      this.sideSheetService.closeSideSheet();
    }
  }

  protected goBackPreviousComponentImmediately(event: SideSheetButtonEvent) {
    if (event?.button?.actionType === ActionType.BACK) {
      this.sideSheetService.closeComponent();
    }
  }

  onClickButton(event: SideSheetButtonEvent): void {
      this.closeSideSheetImmediately(event);
      this.goBackPreviousComponentImmediately(event);
  }

}
