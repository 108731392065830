import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlSanitizerPipe } from './html-sanitizer';
import { FunctionCallPipe } from './function-call';
import { HourPipe } from './hour';
import {TruncateHtmlPipe, TruncatePipe} from './truncate';
import { TruncateHtmlPlainTextPipe } from 'tuula-common';

const StandalonePipes=[FunctionCallPipe,HtmlSanitizerPipe,HourPipe,TruncatePipe, TruncateHtmlPipe,TruncateHtmlPlainTextPipe];

@NgModule({
  imports: [CommonModule,...StandalonePipes],
  declarations: [],
  exports: [...StandalonePipes],
  providers: [],
})
export class PipesModule {}
