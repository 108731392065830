<div style="display: flex" (click)="$event.stopPropagation()">
  <!-- Visible Buttons -->
  <ng-container *ngFor="let button of visibleButtons">
    <ng-container *ngTemplateOutlet="button.template"></ng-container>
  </ng-container>

  @if(overflowButtons.length > 0){
    <ng-container>
      <div class="{{moreButtonCssClass()}}" [matMenuTriggerFor]="tableRowOverflowMenu">
        <i class="ai-icon-dots-vertical"></i>
      </div>
    </ng-container>
  }
</div>

<mat-menu #tableRowOverflowMenu="matMenu" class="tuula-menu">
  <ng-container *ngFor="let button of overflowButtons">
      <ng-container *ngTemplateOutlet="button.template"></ng-container>
  </ng-container>
</mat-menu>
