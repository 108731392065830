import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SideSheetService } from '../../../services';
import { Subscription } from 'rxjs';
import { NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SidesheetPopupRef } from '../../../models/side-sheet/sidesheet-popup-ref';

@Component({
  selector: 'app-sidesheet-popup',
  templateUrl: './sidesheet-popup.component.html',
  styleUrls: ['./sidesheet-popup.component.scss'],
  standalone:true,
  imports: [NgIf,NgStyle,TranslateModule,NgTemplateOutlet]
})
export class SidesheetPopupComponent implements OnInit, OnDestroy {
  @Input() sidesheetPopupRef: SidesheetPopupRef;
  @Output() popupClosed = new EventEmitter<SidesheetPopupRef>();


  private subscription: Subscription = new Subscription();

  constructor(private sideSheetService: SideSheetService) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closePopup(data) {
    this.sidesheetPopupRef.content.data = {confirmed: data};
    this.popupClosed.emit(this.sidesheetPopupRef);
  }

  onClose() {
    this.closePopup(null);
  }

  onCancel() {
    this.closePopup(false)
  }

  onOk() {
    this.closePopup(true)
  }
}
