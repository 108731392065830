import { PersonSubsetModel } from '../comment/comment.model';

export class SubGoalModel {
  id?: string;
  refId: string;
  maingoalId: string;
  tenantId: string;
  name: string;
  selected?: boolean;
  startingLevel?: number;
  objective?: number;
  score: number;
  status: string;
  updatedAt: Date;
  isNew: boolean;
  isRenamed: boolean;
  manuallyMarked?: boolean;
  achieved: boolean;
  scoreHistory?: ScoreHistoryModel[];
}

export class MainGoalModel {
  id?: string;
  refId: string;
  socialareaId: string;
  tenantId: string;
  name: string;
  subGoals: SubGoalModel[];
  selected?: boolean;
  startingLevel?: number;
  objective?: number;
  evaluation?: number;
  summary?: string;
  status: string;
  updatedAt: Date;
  isNew: boolean;
  isRenamed: boolean;
  score?: number;
  manuallyMarked?: boolean;
  achieved: boolean;
  scoreHistory?: ScoreHistoryModel[];
  // frontend calculation for manuallyMarked if all subgoals are achieved
  manualMarkedEnabled?: boolean;
}

export class SocialAreaModel {
  id?: string;
  refId: string;
  tenantId: string;
  entityId: string;
  entityType: string;
  name: string;
  selected?: boolean;
  additionalInfo?: string;
  mainGoals: MainGoalModel[];
  status: string;
  updatedAt: Date;
  updatedBy: PersonSubsetModel | undefined;
  isNew: boolean;
  isRenamed: boolean;
  scoringAnswered: boolean;
  isInvalid ?: boolean;
}

export class ScoreHistoryModel {
  date: Date;
  createdBy: {
    id: string;
    fullName: string;
  };
  entityVersion: number;
  score: number;

  displayCareplan: string;
}

export class GoalHistoryModel {
  averageScore: number;
  history: ScoreHistoryModel[];
}
