<div class="sidesheet-header-container">
  <div class="sidesheet-header-left">
    <span class="breadcrumb-back-icon-wrapper zoom-in" (click)="onBackButton()">
      <i class="ai-icon-arrow-narrow-left ai-icon-1-5x"></i>
    </span>
    <div class="sidesheet-header-title">
      @if(!sideSheetConfig()?.doNotShowNewBreadcrumbTitle){
        <div class="header-title">
          {{sideSheetContent().title | translate}}
        </div>
      }
      @if(!sideSheetConfig()?.doNotShowNewBreadcrumbText){
        <div class="breadcrumb">
            @for (item of formerSidesheets(); track $index; let i = $index; let last = $last ; let first = $first; let count = $count) {
              @if(count > 1) {
                <ng-container >
                  @if(!first){
                    <span class="breadcrumb-chevron {{last ? 'breadcrumb-last' : ''}}">
                      <i class="ai-icon-chevron-right"></i>
                    </span>
                  }
                  <span
                     class="{{last ? 'breadcrumb-last' : ''}}"
                     (click)="loadComponent(item?.componentRef?.componentType, item?.content, item?.config)">
                     @for(breadcrumb of (item?.content?.breadcrumb | functionCall:breakFirstBreadcrumb); track $index; let last = $last; let first = $first; let count = $count) {
                      @if(!first){ } 
                      {{(breadcrumb | translate)}}
                     }
                  </span> 
                </ng-container>
              }
            }
        </div>
      }
    </div>
  </div>
  <div class="sidesheet-header-right">
    <span class="fullscreen-button zoom-in" (click)="toggleFullScreen()">
      <i class="ai-icon-{{expandClass()}} expand-icon"></i>
    </span>
    <span class="close-button zoom-in" (click)="onCloseButton()"><i class="ai-icon-x-close "></i></span>
  </div>
</div>
