import { Subject } from 'rxjs';
import { SideSheetPopupConfig, SideSheetPopupContent } from 'tuula-common';

export class SidesheetPopupRef {
  private sidesheetPopupCloseSubject = new Subject<any>();
  public content: SideSheetPopupContent;
  public config: SideSheetPopupConfig;

  constructor(content: SideSheetPopupContent, config: SideSheetPopupConfig) {
    this.content = content;
    this.config = config;
  }

  public afterPopupClosed() {
    return this.sidesheetPopupCloseSubject.asObservable();
  }

  close(data: any): void {
    this.sidesheetPopupCloseSubject.next(data);
    this.sidesheetPopupCloseSubject.complete();
  }
}
