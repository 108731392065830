import {
  CommandLinePosition,
  Position,
  SideSheetContent,
  SideSheetConfig,
  Button,
  SideSheetSize,
  CommanLineElementPosition,
  SideSheetBackdropType,
} from '../../models/side-sheet';
export function createDefaultSideSheetConfig(): SideSheetConfig {
  return {
    position: Position.END,
    opacity: 1,
    style: {
      drawer: {},
      titleBar: {},
      // breadcrumb: {},
      body: {},
      commandLine: {},
    },
    size: SideSheetSize.SM,
    fullscreen: false,

    hasBackdrop: true,
    backdropType: SideSheetBackdropType.OPAQUE,
    disableCloseOnBackdropClick: true,

    titleBarVisible: false,
    titleBarCloseVisible: true,
    titleBarFullscreenVisible: true,
    titleBarBackButtonVisible: true,

    breadcrumbVisible: true,
    breadcrumbCloseVisible: true,
    breadcrumbFullscreenVisible: true,
    breadcrumbBackButtonVisible: true,

    sidesheetCloseButtonAutoCloseActionEnabled: true,
    sidesheetBackButtonAutoBackActionEnabled: true,

    commandLineVisible: true,
    commandLinePosition: CommandLinePosition.BOTTOM,

    showNewBreadcrumb: false,
    doNotShowNewBreadcrumbText: false,
    doNotShowNewBreadcrumbTitle: false
  };
}

export function createDefaultSideSheetContent(): SideSheetContent {
  return {
    title: '',
    subTitle: '',
    buttons: [
      // createSideSheetCloseButton(),
      // createSideSheetBackButton(),
      // createSideSheetDeleteButton(),
      // createSideSheetCreateButton(),
    ]
  };
}

export function createButton(
  name: string,
  actionType: string,
  style?: any,
  classes?: string[],
  position: CommanLineElementPosition = CommanLineElementPosition.RIGHT,
): Button {
  return {
    name,
    actionType,
    style,
    classes,
    position,
    disabled: false,
    visible: true,
    loading: false,
  };
}

export function createPrimaryButton(name: string, actionType: string): Button {
  return createButton(
    name,
    actionType,
    {},
    ['btn-primary']
  );
}
export function createSecondaryButton(name: string, actionType: string): Button {
  return createButton(
    name,
    actionType,
    {},
    ['btn-secondary']
  );
}
export function createLightButton(name: string, actionType: string): Button {
  return createButton(
    name,
    actionType,
    {},
    ['btn-light']
  );
}
