@if(!isHtml()){
    <quill-editor
      [style] = "style()"
      [formControl]="control()"
      (onBlur)="blur($event)"
      (onSelectionChanged)="selectionChanged($event)"
      placeholder="{{ placeholder() | translate}}"
      [modules]="isToolbar() ? quillModules: { toolbar: false }"
      (onEditorCreated)="created($event)"
      [readOnly]="readOnly()"
    ></quill-editor>
  }
  @if(isHtml()){
    <quill-view-html
      [style] = "style()"
      placeholder="{{ placeholder() | translate}}"
      [content]="content()"
      class="class()"
    >
    </quill-view-html>
  }