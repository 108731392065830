import {
  Component,
  DestroyRef,
  Host,
  inject,
  Input,
  Optional,
  signal,
  SkipSelf,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  ControlContainer,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { tap } from 'rxjs';
import { DEFAULT_INPUT_TYPE } from '../../constants';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'app-nd-form-input-text',
  templateUrl: './nd-form-input-text.component.html',
  styleUrls: ['./nd-form-input-text.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    NgxMaskDirective,
    TranslateModule,
    ValidationMessageComponent,
  ],
  providers: [provideNgxMask()],
})
export class FormInputTextNdComponent {
  readonly testId = input.required<string, string>({ transform: (value: string) => {
        return 'text-input-' + value;
    } });
  readonly label = input<string>(''); // Label for the input field
  readonly placeholder = input<string>(''); // Placeholder for the input
  readonly hint = input<string>(undefined);
  readonly alignHint = input<'start' | 'end'>('start');

  @Input() type = DEFAULT_INPUT_TYPE; // Type of the input (text, number, email, etc.)
  readonly transformFns = input<((value: string) => string)[]>([]); // Accept an array of transformation functions
  readonly disabled = input<boolean>(false);

  readonly step = input(1);
  readonly mask = input<string | null>(null);

  readonly minNumberValue = input(Number.MIN_SAFE_INTEGER);
  readonly maxNumberValue = input(Number.MAX_SAFE_INTEGER);
  readonly maxLength = input(100);
  readonly minLength = input(0);
  readonly dropSpecialCharacters = input<boolean>(false);

  @Input() autofocus: boolean = false;
  @Input() suffix: string;
  @Input() prefix: string;

  readonly controlName = input<string>(undefined); // Name of the form control
  @Input() control: AbstractControl | null;

  required = signal<boolean>(false);

  readonly #destroyRef = inject(DestroyRef);

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit() {
    const controlName = this.controlName();
    if (!this.control && controlName && this.controlContainer) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        controlName,
      );
    } else {
      this.control = new FormControl();
    }

    if (this.control) {

      this.addDefaultValidators();
      this.setDefaultValues();

      if (this.disabled()) {
        this.control.disable();
      }

      this.control.valueChanges
        .pipe(
          // Ensure the logic only applies once after the value change event is triggered
          tap((value: string) => {
            let transformedValue = value;
            this.transformFns().forEach((fn) => {
              transformedValue = fn(transformedValue);
            });

            // Set the transformed value without triggering a new event
            if (value !== transformedValue) {
              this.control?.setValue(transformedValue, { emitEvent: true });
            }
          }),
          takeUntilDestroyed(this.#destroyRef),
        )
        .subscribe();
    }
  }

  addDefaultValidators() {
    if (this.type === 'number') {

      const minNumberValue = this.minNumberValue();
      if (minNumberValue !== null && !this.control?.hasValidator(Validators.min(minNumberValue))) {
        this.control?.addValidators(Validators.min(minNumberValue));
      }

      const maxNumberValue = this.maxNumberValue();
      if (maxNumberValue !== null && !this.control?.hasValidator(Validators.max(maxNumberValue))) {
        this.control?.addValidators(Validators.max(maxNumberValue));
      }

    } else if (this.type === 'text') {
      const maxLength = this.maxLength();
      if (maxLength !== null && !this.control?.hasValidator(Validators.maxLength(maxLength))) {
        this.control?.addValidators(Validators.maxLength(maxLength));
      }
      const minLength = this.minLength();
      if (minLength !== null && minLength > 0 && !this.control?.hasValidator(Validators.minLength(minLength))) {
        this.control?.addValidators(Validators.minLength(minLength));
      }
    }

    this.control?.updateValueAndValidity();
  }

  setDefaultValues() {
    this.required.set(this.control?.hasValidator(Validators.required)!);
  }

}
