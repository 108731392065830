<app-panel type="popup">
  <mat-drawer-container class="c4p-color-container" hasBackdrop='true' style='height: 490px;'>
    <mat-drawer position="end" [opened]="openComp" mode="over" style='width: 100%; background-color: rgba(0,0,0,0.3);'>
      <lib-document-signature-log-detail
        [data]="detailData" [fileData]="detailFileData"
        (closeDetailComp)="closeDetailSignature($event)"
        style="height: -webkit-fill-available;
        display: flex;">
      </lib-document-signature-log-detail>
    </mat-drawer>
    <mat-drawer *ngIf='openShare' [opened]="openShare" mode="over" style='width: 100%; background-color: rgba(0,0,0,0.3)'>
      <app-sharing-detail [data]='data' (closeDetailComp)="closeShare($event)" style="height: 100%; display: flex;">
      </app-sharing-detail>
    </mat-drawer>
    <mat-drawer-content style='display: flex'>
      <app-panel style='display:flex; width: 100%;' [type]="'popup-share-and-sign'">
          <div style='flex-grow: 1; '>
            <app-table-view
              [pageSizeOptions]="[5]"
              [pageSize]="5"
              (refreshRequest)="onRefreshRequest($event)"
              [showSelectAll]="true"
              [options]="tableOptions"
              (allCheck)="onAllCheck($event)"
              (checkboxChanged)='singleCheck($event.event, $event.element, $event.columnId)'
              [emptyCheck]='sharedPersons?.length === 0 && signatureLogs?.length === 0'
              [checkData]='sharedPersons?.length > 0'
              (numberInputChanged)='onNumberInputChanged($event)'
              (dateInputChanged)='onDateInputChanged($event)'>
              <ng-container panelButtons>
                <ng-container *ngTemplateOutlet="buttons"></ng-container>
              </ng-container>
              <ng-container text *ngIf='!(sharedPersons && sharedPersons?.length) && signatureLogs?.length === 0'>
                <ng-container *ngTemplateOutlet="text"></ng-container>
              </ng-container>
            </app-table-view>
            <ng-template #buttons>
              <button *ngIf='hasSharePermission' class="c4p-button" translate (click)="shareFile()">general.actions.ShareFile</button>
            </ng-template>
          </div>
        <div style="display: flex; margin-bottom: 10px">
              <span class="c4p-button-cancel" (click)="close()" translate>general.labels.Close</span>
              <span *ngIf='hasSignPermission && isPdfDocument && !disableSignButton' class="c4p-button-ok" (click)="submit()">
              <span translate>general.actions.SignDocument</span>
              </span>
        </div>
      </app-panel>
    </mat-drawer-content>
  </mat-drawer-container>
  </app-panel>
<ng-template #buttons>
  <button
    *ngIf='hasSharePermission'
    class="c4p-button"
    translate
    (click)='shareFile()'
  >
    general.actions.ShareFile
  </button>
</ng-template>
<ng-template #text>
  <div>
    <span translate>document.labels.documentHasNotBeenShared </span>
  </div>
</ng-template>
