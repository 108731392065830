import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../../../general-components/dialog/dialog.component';
import { Dsm5DiagnosisDetailComponent } from '../../../components/dsm5-diagnosis-detail/dsm5-diagnosis-detail.component';
import {
  CompleteCareProgramModel,
  DSM5DiagnosisModel,
} from '../../../models/care-program.model';
import { CareProgramService } from '../../../services/care-program.service';
import { BreadcrumbStService } from 'c4p-portal-util';

@Component({
  selector: 'app-dsm5-diagnosis-section',
  templateUrl: './dsm5-diagnosis-section.component.html',
  styleUrls: ['./dsm5-diagnosis-section.component.scss'],
})
export class Dsm5DiagnosisSectionComponent implements OnInit {
  breadcrumbService = inject(BreadcrumbStService);
  public get careprogramForm(): FormGroup {
    return this.careprogramService.careProgramForm;
  }

  public get dsm5DiagnosisFormArray(): FormArray {
    return this.careprogramForm.get('dSM5Diagnosis') as FormArray;
  }

  public displayedColumns = [
    'startDateDiagnosis',
    'endDateDiagnosis',
    'refCodeICD9CM',
    'description',
    'operations',
  ];

  constructor(
    private careprogramService: CareProgramService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.updateBreadCrumbWithLabel('general.menu.Carefiles','general.menu.Carefiles',false,'/carefiles');
    this.initDiagnosisData();
  }

  private initDiagnosisData() {
    const selectedCareProgram: CompleteCareProgramModel = this
      .careprogramService.selectedCareProgram$
      .value as CompleteCareProgramModel;
    if (
      selectedCareProgram.dSM5Diagnosis &&
      this.dsm5DiagnosisFormArray.length === 0
    )
      selectedCareProgram.dSM5Diagnosis.forEach((diagnosis) => {
        this.dsm5DiagnosisFormArray.push(
          this.buildDsm5DiagnosisFormGroup(diagnosis),
        );
      });
  }
  private buildDsm5DiagnosisFormGroup(dSM5Diagnosis?: DSM5DiagnosisModel) {
    const diagnosisData = dSM5Diagnosis?.diagnosis;
    const dSM5DiagnosisForm = this.formBuilder.group({
      startDateDiagnosis: [
        dSM5Diagnosis?.startDateDiagnosis,
        Validators.required,
      ],
      endDateDiagnosis: [dSM5Diagnosis?.endDateDiagnosis],
      diagnosis: this.formBuilder.group({
        startDate: [diagnosisData?.startDate, Validators.required],
        endDate: [diagnosisData?.endDate, Validators.required],
        code: [diagnosisData?.code, Validators.required],
        groupCode: [diagnosisData?.groupCode],
        description: [diagnosisData?.description, Validators.required],
        hierarchyLevel: [
          diagnosisData?.hierarchyLevel,
          [Validators.required, Validators.min(0)],
        ],
        selectable: [diagnosisData?.selectable],
        refCodeICD9CM: [diagnosisData?.refCodeICD9CM],
        refCodeICD10: [diagnosisData?.refCodeICD10],
        claimType: [diagnosisData?.claimType],
        mutations: [diagnosisData?.mutations],
        identifier: [diagnosisData?.identifier],
        GDS801Code: [diagnosisData?.GDS801Code],
        mainGroupCode: [diagnosisData?.mainGroupCode],
      }),
    });

    if (this.dsm5DiagnosisFormArray.disabled) dSM5DiagnosisForm.disable();

    return dSM5DiagnosisForm;
  }
  createDiagnosis() {
    const dialogRef = this.dialog.open(Dsm5DiagnosisDetailComponent, {
      data: {
        diagnosis: null,
        viewOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe((diagnosis: DSM5DiagnosisModel) => {
      if (diagnosis) {
        const dSM5DiagnosisForm = this.buildDsm5DiagnosisFormGroup(diagnosis);
        this.dsm5DiagnosisFormArray.push(dSM5DiagnosisForm);
        dSM5DiagnosisForm.markAsDirty();
        this.dsm5DiagnosisFormArray.markAsTouched();
      }
    });
  }

  viewDsm5Diagnosis(index: number) {
    const diagnosis = this.dsm5DiagnosisFormArray.get([index]).value;
    const dialogRef = this.dialog.open(Dsm5DiagnosisDetailComponent, {
      data: {
        diagnosis,
        viewOnly: true,
      },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  editDsm5Diagnosis(diagnosis: DSM5DiagnosisModel, index: number) {
    const dialogRef = this.dialog.open(Dsm5DiagnosisDetailComponent, {
      data: {
        diagnosis,
        viewOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const dSM5DiagnosisForm = this.dsm5DiagnosisFormArray.get([index]);
        dSM5DiagnosisForm.patchValue(res);
        dSM5DiagnosisForm.markAsDirty();
      }
    });
  }

  deleteDsm5Diagnosis(index: number) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'general.messages.DeleteItem',
        message: 'careprogram.messages.AreYouSureYouWantToDeleteDSM5Diagnosis',
        confirmText: 'general.actions.delete',
        method: 'DELETE',
      },
      panelClass: 'dialog-delete',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.dsm5DiagnosisFormArray.markAsDirty();
        this.dsm5DiagnosisFormArray.removeAt(index);
        this.dsm5DiagnosisFormArray.markAsTouched();
      }
    });
  }
}
