import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {
  DocumentDetailComponent,
  DocumentService,
  ITableOption,
  ITableView,
  UserInfoService,
  DocumentModel
} from 'c4p-portal-util';
import { DigitalSignatureService } from '../../service/signature/digital-signature.service';
import { SignatureActionSummaryComponent } from '../../components/signature-action-summary/signature-action-summary.component';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Subject, takeUntil} from 'rxjs';
import { DocumentSignComponent } from '../../components/document-sign/document-sign.component';

@Component({
  selector: 'lib-document-signature-log',
  templateUrl: './document-signature-log.component.html',
  styles: [
    'h1 { margin: 0;\n' +
      '    font-size: 18px;\n' +
      '    color: #1378a3;\n' +
      '    padding-top: 27px;\n' +
      '    text-align: center; }',
  ],
})
export class DocumentSignatureLogComponent implements OnInit, OnDestroy {

  private readonly destroyed$ = new Subject<boolean>();
  public tableOptions: ITableOption = null!;
  public fileData: DocumentModel;
  public detailFileData: DocumentModel;
  public userPortal: string;
  public operation: string;
  public userInfo;
  detailData: any;
  signatureLogs: any;
  signatureLogs$: BehaviorSubject<any>;
  opened:boolean = false;
  sharedPersons: any;
  @ViewChild('detailDialog') detailDialog: TemplateRef<any>;

  public tableView: ITableView = {
    pageIndex: 0,
    pageSize: 20,
    sortColumn: 'createdAt',
    sortDirection: 'desc',
    search: '',
  };
  constructor(
    public dialogRef: MatDialogRef<DocumentDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public mode: boolean,
    private digitalSignatureService: DigitalSignatureService,
    public userInfoService: UserInfoService,
    private documentService: DocumentService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private translate: TranslateService,
  ) {
    this.signatureLogs$ = new BehaviorSubject<any>(null);
    this.userPortal = data.userPortal;
    this.operation = data.operation;
    this.initializePendingSignatures(data.document);
    this.sharedPersons = data.document.sharing;
    this.userPortal = data.userPortal;
    this.operation = data.operation;
    this.signatureLogs = data.document.signatureLogs.map(log => {
      return {
        ...log,
        status: 'Signed'
      }
    });
    this.sharedPersons = data.document.sharing;
    this.dialogRef.disableClose = false;
  }

  private initializePendingSignatures(data) {
    const pendingSignatures = data.sharing.filter(shr => shr.signatureRequired && !data.signatureLogs?.some(log => log.signedBy.id === shr.userId));

    const pendingSignatureLogs = pendingSignatures?.map(ps => {
      return {
        signedBy: {
          fullName: ps.fullName,
        },
        userId: ps.userId,
        documentId: data.id,
        signedDate: '',
        status: 'pending',
      };
    });

    this.signatureLogs = data?.signatureLogs?.map(log => {
      return {
        ...log,
        status: 'signed',
      };
    });
    if (pendingSignatureLogs) this.signatureLogs.push(...pendingSignatureLogs);

    this.signatureLogs$.next({
      data: this.signatureLogs
    })
  }

  ngOnInit(): void {
    this.tableOptions = this.buildTable();
    this.fileData = this.data.document;
    this.fileData = this.data.document;
    this.userInfoService.userInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((userInfo) => {
        this.userInfo = userInfo;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onCloseDialog() {
    this.dialogRef.close(null);
  }
  onButtonClick(params: any) {
    const signatureKey = params.signatureKey.split(',');
    this.digitalSignatureService
      .getSignatureKeys(signatureKey)
      .subscribe((res) => {
        const data = res?.[0];
        this.openDialog(data, this.fileData);
      });
  }
  onRowClick(log){
    if(log.status === 'pending') return;
    this.opened = !this.opened;
    const signatureKey = log.signatureKey;
    this.digitalSignatureService
      .getSignatureKeys([signatureKey])
      .subscribe((res) => {
        const data = res?.[0];
        this.openDialog(data, this.fileData);
      });
  }
  openDialog(data, fileData): void {
    this.detailData = data;
    this.detailFileData = fileData;
    this.opened = true;
  }

  public sign(): void {
    const document = this.fileData;
    this.dialog.open(DocumentSignComponent, {
      data: {
        document,
        userPortal: this.userPortal,
        operation: this.operation,
      },
    });
  }

  buildTable(): ITableOption {
    return {
      name: 'document.labels.DocumentSignatures',
      titleIcon: '',
      dataObservable: this.signatureLogs$,
      columns: [
        {
          id: 'signedBy',
          name: 'document.labels.SignedBy',
          objectProperty: 'fullName',
          type: 'object',
          width: 80,
        },
        {
          id: 'signedDate',
          name: 'document.labels.SignedDate',
          type: 'date',
          format: 'date',
          width: 50,
        },
        {
          id: 'status',
          name: 'general.labels.Status',
          type: 'status',
        }
      ],
      inlineActions: [],
      menuActions: [
        {
          icon: 'visibility',
          tooltip: 'general.actions.View',
          method: (data) => this.onRowClick(data),
        },
        {
          icon: 'mail_outline',
          tooltip: 'document.labels.sendReminder',
          method: (data) => this.onNotifyButtonClick(data),
          conditionMethod: (data) => data.status !== 'signed',
        },{
          icon:'cancel',
          tooltip: 'document.labels.Revoke',
          method: (data) => this.onRevokeButtonClick(data),
          conditionMethod: (data) => data.status !== 'signed',
        }
      ],
      showHeader: true,
      showSearch: true,
      showPagination: false,
      externPagination: true,
      defaultPageSize: 20,
      defaultSortColumn: 'createdAt',
      defaultSortDirection: 'desc',
    };
  }

  closeDetailSignature($event: boolean) {
    if ($event) {
      this.opened = false;
      this.opened = false;
    }
  }

  private onRevokeButtonClick(data) {
    this.documentService
      .revokeSignature(data.documentId, data.userId, this.userInfo)
      .subscribe({
        next: (value) => {
          this.data = value;
          this.sharedPersons= value.sharing;
          this.toastr.success(this.translate.instant('document.messages.requestRevoked'));
          this.initializePendingSignatures(value)
        },
        error: (err) => console.error('Observable emitted an error: ' + err),
      });
  }

  private onNotifyButtonClick(data: any) {
    this.documentService
      .notifySignature(data.documentId, data.userId, this.userInfo)
      .subscribe({
        next: (value) => {
          this.data = value;
          this.sharedPersons = value.sharing;
          this.toastr.success(this.translate.instant('document.messages.notifySuccess',{
            value: (value.sharing.find(res=>res.userId === data.userId)).resend.length }));
          this.initializePendingSignatures(value)
        },
        error: (err) => console.error('Observable emitted an error: ' + err),
      }).add();
  }

  protected readonly event = event;
}

interface PendingSignatures {
  fullName: string;
  status: string;
  userId: string;
  documentId: string;
}
