import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateHtml',
  standalone: true,
})
export class TruncateHtmlPipe implements PipeTransform {
  transform(value: string, limit: number = 180, ellipsis: string = '...'): string {
    const textOnly = value.replace(/<[^>]+>/g, '');
    return textOnly.length > limit ? textOnly.substring(0, limit) + '...' : textOnly;
  }
}
