<mat-form-field appearance="outline" class="table-header-filter-form-input-field">
  <input
    matInput
    [formControl]="$any(control)"
    [matDatepicker]="datepicker"
    placeholder="{{ 'general.labels.SelectMonth' | translate }}"
    (focus)="datepicker.open()"
    (click)="datepicker.open()"
  />
  <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
  <mat-datepicker
    #datepicker
    startView="multi-year"
    (monthSelected)="setMonthAndYear($event, datepicker)"
    panelClass="month-picker">
  </mat-datepicker>
</mat-form-field>
