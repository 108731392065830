import {SUPERSET_CONSTANTS} from 'c4p-portal-util';

export const globalSitEnvironment = {
  production: false,
  oldDashboardEnabled: true,
  communicationManagementApiUrl: 'api/cm/contacts',
  employeeManagementApiUrl: 'api/ems/employees',
  addressManagementApiUrl: 'api/ams/addresses',
  tenantManagementApiUrl: 'api/tms/tenants',
  systemAdminApiUrl: 'api/sas',
  clientManagementApiUrl: 'api/cms/clients',
  applicationManagementApiUrl: 'api/aps/applications',
  masterdataApiUrl: 'api/mds',
  questionsetManagementApiUrl: 'api/qss',
  icommunicationApiUrl: 'api/icm/messages',
  clientNetworkManagementUrl: 'api/cns/client-networks',
  gatekeeperServiceApiUrl: 'api/gks/gatekeepers',
  gatekeeperConfigurationsServiceApiUrl: '/api/gks/configurations',
  teamManagementApiUrl: '/api/tm/teams',
  carefileManagementApiUrl: '/api/cf/carefiles',
  assessmentWidgetManagementApiUrl: '/api/pp',
  documentManagementApiUrl: '/api/dms',
  productCatalogApiUrl: '/api/pc',
  keycloakManagementApiUrl: '/api/kms',
  logbookServiceApiUrl: '/api/lgb/logbook',
  hourManagementApiUrl: '/api/hm',
  docgenPortalUrl: 'https://docgen.sit.tuulasoft.com',
  careplanManagementApiUrl: '/api/cf/carefiles',
  noteManagementApiUrl: '/api/cf/notes',
  reportManagementApiUrl: '/api/rm',
  invoiceManagementApiUrl: '/api/im',
  medicationModuleUrl: '/api/mms',
  collaborationServiceWS: 'wss://sit.tuulasoft.com:443/ws/',
  notificationManagementApiUrl: '/api/nm',
  topicCarefileManagementApiUrl: '/api/cm',
  commentApiUrl: '/api/cm/comments',
  docgenServiceApiUrl: '/api/dg',
  participationServiceApiUrl: '/api/pp',
  performanceTariffApiUrl: '/api/cf',
  performanceTariffTemplateApiUrl: '/api/cf',
  pharmacyManagementApiUrl: '/api/phm',
  careprogramServiceApiUrl: '/api/cf/careprogram',
  dwhServiceApiUrl: '/api/ds',
  digitalSignatureApiUrl: '/api/dgs',
  referenceDataManagementApiUrl: '/api/rdm',
  supportEmail: 'tuula@prodeba.nl',

  idleTimeout: 7200000,
  enableLangSwich: true,

  vapid:
    'BBYCTpw6CgtqB8gaKM-YDrJGhmwlfaQoxP2RZDSspg43aOsiOUe2avX4rkeeRpt_cELjI_BGgw2auIFEn3kE2Xc',
  firebase: {
    apiKey: "AIzaSyAsPFxpYHklnri6RoHCMluwQ1wZnk5Fack",
    authDomain: "tuula-dev-445710.firebaseapp.com",
    projectId: "tuula-dev-445710",
    storageBucket: "tuula-dev-445710.firebasestorage.app",
    messagingSenderId: "510278730607",
    appId: "1:510278730607:web:036b339a18a2a83f4b9e9a"
  },
  tuulaDocumentationUrl: '',
  serviceDeskUrl: '',
  supersetApiUrl: 'https://bi.tuulasoft.com',
  dashboards: [
    {
      name: SUPERSET_CONSTANTS.TARIFF_AND_TRENDS,
      permissions: [],
      childs: [
        {
          name: SUPERSET_CONSTANTS.EXTERNAL_PRODUCTS,
          id: '7616b7fb-e187-4a8f-97c7-8404aea766a8',
        },
        {
          name: SUPERSET_CONSTANTS.PERFORMANCE_TARIFF,
          id: '04faec7b-2826-4e15-b330-7773d723e37a',
        },
      ],
    }
  ],
};
