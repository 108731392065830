import {
  Directive,
  Input,
  HostListener,
  OnDestroy,
  TemplateRef, ComponentRef, Injector, ViewContainerRef, ChangeDetectorRef
} from '@angular/core';
import { TooltipComponent } from 'tuula-common';
import { Subject } from 'rxjs';


@Directive({
  selector: '[tooltip]',
  standalone: true,
})
export class TooltipDirective implements OnDestroy {

  public static activeTooltip: TooltipDirective | null = null;
  private readonly destroy$ = new Subject();
  @Input('tooltip') objects: string;
  @Input() placement: string = 'bottom';
  @Input() backgroundColor: string = 'default';
  @Input() tooltipDelay: number = 200;
  @Input() template: TemplateRef<any>;
  @Input() hideOnTooltip: boolean = true;

  tooltipVisibilityDelay: number = 100;

  offset = 30;
  tooltipRef: ComponentRef<TooltipComponent> = null;
  hostElementRef: any;
  private boundCheckMouseOutside: (event: MouseEvent) => void;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private injector: Injector,
    private cdr: ChangeDetectorRef
  ) {
    this.hostElementRef = viewContainerRef.element.nativeElement;
    this.boundCheckMouseOutside = this.checkMouseOutside.bind(this);
  }



  @HostListener('click') onClick(){
    this.hide();
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  @HostListener('mouseenter',['$event']) onMouseEnter(event:MouseEvent) {
    setTimeout(() => {
      if (TooltipDirective.activeTooltip && TooltipDirective.activeTooltip !== this) {
        TooltipDirective.activeTooltip.hide(); // Remove the old tooltip
      }
      if(!this.tooltipRef){
        this.show();
        TooltipDirective.activeTooltip = this;
      }
    }, this.tooltipVisibilityDelay);
  }

  @HostListener('mouseleave',['$event']) onMouseLeave(event: MouseEvent): void {
      this.checkAndHide(event);
  }

  @HostListener('mousemove') onMouseMove() {
    if (this.tooltipRef) {
      this.setPosition();
    }
  }


  private checkMouseOutside(event: MouseEvent): void {
    if (!this.tooltipRef) this.hide();
    const tooltipEl = this.tooltipRef?.location.nativeElement;
    const tooltipContainer = tooltipEl?.querySelector('.c4p-tooltip');
    if(!tooltipContainer) this.hide();
    const inTooltipBoundary = this.isInBoundary(tooltipContainer, event);
    const inHostBoundary = this.isInBoundary(this.hostElementRef, event);

    const isInBoundary = inHostBoundary || (!this.hideOnTooltip && inTooltipBoundary)
    if (!isInBoundary) {
      this.hide();
      document.removeEventListener('mousemove', this.boundCheckMouseOutside); // Remove listener
    }
  }

  show() {
    this.createTooltip();
    this.setPosition();
    this.cdr.detectChanges();
  }

  hide(): void {
    setTimeout(() => {
      this.removeTooltip();
      if (TooltipDirective.activeTooltip === this) {
        TooltipDirective.activeTooltip = null; // Reset active tooltip only if it's the same
      }
    }, this.tooltipDelay);
  }

  private createTooltip() {
    const injector = {
      injector: Injector.create({
        providers: [
          { provide: 'content', useValue: this.objects },
          { provide: 'tooltipClasses', useValue: [
            'c4p-tooltip',
              `c4p-tooltip-bg-${this.backgroundColor}`,
            ]
          },
          { provide: 'template', useValue: this.template },

        ],
        parent: this.injector,
      })
    }
    this.tooltipRef = this.viewContainerRef.createComponent(TooltipComponent,injector);
    const tooltipEl = this.tooltipRef.location.nativeElement;
    TooltipDirective.activeTooltip = this;
    document.body.appendChild(tooltipEl);
    tooltipEl.addEventListener('mouseleave', (event) => {
      this.checkAndHide(event);
    });
  }

  private removeTooltip() {
      if (this.tooltipRef) {
        this.viewContainerRef.detach(0);
        this.tooltipRef.destroy();
        this.tooltipRef = null;
        TooltipDirective.activeTooltip = null;
      }
  }

  checkAndHide(event: MouseEvent): void {
    if(!this.tooltipRef) this.hide();
    const tooltipEl = this.tooltipRef?.location?.nativeElement;
    const tooltipContainer = tooltipEl?.querySelector('.c4p-tooltip'); // Child element

    if(!tooltipContainer) this.hide();
    const inTooltipBoundary = this.isInBoundary(tooltipContainer,event)
    const hostBoundary = this.isInBoundary(this.hostElementRef,event)

    if (!inTooltipBoundary && !hostBoundary) {
      this.hide();
      document.removeEventListener('mousemove', this.boundCheckMouseOutside);
    }
    else document.addEventListener('mousemove', this.boundCheckMouseOutside);
  }

  isInBoundary(el: HTMLElement, event: MouseEvent): boolean {
    if (!el) return false;

    const rect = el.getBoundingClientRect();
    const buffer = 15; // Optional: Add a small buffer to avoid accidental hides

    return (
      event.clientX >= rect.left - buffer &&
      event.clientX <= rect.right + buffer &&
      event.clientY >= rect.top - buffer &&
      event.clientY <= rect.bottom + buffer
    );
  }

  private setPosition() {
    if (!this.tooltipRef) return;
    const tooltipEl = this.tooltipRef.location.nativeElement;
    const tooltipContainer = tooltipEl.querySelector('.c4p-tooltip'); // Child element
    const tooltipPos = tooltipContainer?.getBoundingClientRect();

    const tooltipWidth = tooltipPos?.width; // You can set a fixed width or estimate it
    const tooltipHeight = tooltipPos?.height; // You can set a fixed height or estimate it
    const hostElementPosition = this.hostElementRef.getBoundingClientRect();
    const hostElementWidth = hostElementPosition.width;
    const hostElementHeight = hostElementPosition.height;
    let top = hostElementPosition.top;
    let left = hostElementPosition.left;
    let placementFlag = this.placement;
    if (top + tooltipHeight +this.offset >= window.innerHeight) {
      placementFlag =  'top';
    }

    tooltipContainer?.classList?.add( `c4p-tooltip-${placementFlag}`);

    // Adjust position based on placement
    switch (placementFlag) {
      case 'bottom':
        top += tooltipHeight; // Tooltip appears below the mouse
        left -= tooltipWidth / 2 - hostElementWidth / 2; // Center horizontally
        break;

      case 'top':
        top -= tooltipHeight + 10; // Tooltip appears above the mouse
        left -= tooltipWidth / 2 - hostElementWidth / 2; // Center horizontally
        break;

      case 'left':
        top -= tooltipHeight / 2 - hostElementHeight / 2; // Center vertically
        left -= tooltipWidth + 15; // Tooltip appears to the left
        break;

      case 'right':
        top -= tooltipHeight / 2 - hostElementHeight / 2; // Center vertically
        left += tooltipWidth + 10; // Tooltip appears to the right
        break;
    }

    if (top < 0) top = 0; // Prevent going above the viewport
    if (left < 0) left = 0; // Prevent going off the left edge
    if (top + tooltipHeight > window.innerHeight) {
      top = window.innerHeight - tooltipHeight;
    }

    if (left + tooltipWidth > window.innerWidth) {
      left = window.innerWidth - tooltipWidth;
    }
    // Apply styles to position the tooltip
    tooltipEl.style.position = 'fixed'; // Use fixed positioning
    tooltipEl.style.top = `${top}px`;
    tooltipEl.style.left = `${left}px`;
  }

}
