import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryOverviewComponent } from './pages/category-overview/category-overview.component';
import { DocumentOverviewComponent } from './pages/document-overview/document-overview.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { CategoryService } from './service/category/category.service';
import { DocumentService } from './service/document/document.service';
import { DocumentDetailComponent } from './components/document-detail/document-detail.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlsModule } from '../form-controls';
import { GeneralComponentsModule } from '../general-components';
import { DirectivesModule } from '../directives';
import { CategoryDetailComponent } from './components/category-detail/category-detail.component';
import { DocumentViewComponent } from './components/document-view/document-view.component';
import { MaterialModule } from '../../material.module';
import { DocumentFiltersFormComponent } from './forms/document-filters-form/document-filters-form.component';
import { SharingDetailComponent } from './components/sharing-detail/sharing-detail.component';
import {CustomDateTimePipe, PipesModule} from '../pipes';
import { DocumentSignComponent } from './components/document-sign/document-sign.component';
import { DocumentSignatureLogComponent } from './pages/document-signature-log/document-signature-log.component';
import { DocumentSignatureLogDetailComponent } from './pages/document-signature-log-detail/document-signature-log-detail.component';
import {AuditLogComponent} from './components/audit-log/audit-log.component';
import {SignatureActionSummaryComponent} from './components/signature-action-summary/signature-action-summary.component';
import { IdentificationMethodComponent } from './components/identification-method/identification-method.component';
import { DocumentSharedHistoryComponent } from './components/document-shared-history/document-shared-history.component';
import { ShareAndSignComponent } from './components/share-and-sign/share-and-sign.component';
import {
  DocumentUploadDetailComponent
} from './components/document-upload-sidesheet/document-upload-detail/document-upload-detail.component';
import {
  DocumentUploadSidesheetComponent
} from './components/document-upload-sidesheet/document-upload-sidesheet.component';
import { FormControlsNdModule } from 'tuula-common';


const moduleComponents = [
  DocumentOverviewComponent,
  CategoryOverviewComponent,
  DocumentsComponent,
  DocumentDetailComponent,
  CategoryDetailComponent,
  DocumentViewComponent,
  DocumentFiltersFormComponent,
  SharingDetailComponent,
  AuditLogComponent,
  DocumentSignatureLogComponent,
  SignatureActionSummaryComponent,
  IdentificationMethodComponent,
  DocumentSharedHistoryComponent,
  DocumentUploadDetailComponent,
  DocumentUploadSidesheetComponent
];
@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        FormControlsModule,
        GeneralComponentsModule,
        DirectivesModule,
        MaterialModule,
        PipesModule,
        FormControlsNdModule
    ],
  declarations: [...moduleComponents, DocumentSignComponent, DocumentSignatureLogDetailComponent, ShareAndSignComponent],
  providers: [DocumentService, CategoryService, CustomDateTimePipe],
  exports: [...moduleComponents],
})
export class DocumentModule {}
