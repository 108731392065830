import { map, Observable } from 'rxjs';
import { Injectable} from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

export enum ScreenBreakpoint {
  SM = '(max-width: 576px)',
  MD = '(max-width: 768px)',
  LG = '(max-width: 992px)',
  XL = '(max-width: 1200px)',
  XXL = '(max-width: 1400px)',
}

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  
  constructor(private breakpointObserver: BreakpointObserver) {}

  isBreakpointActive(breakpoint: ScreenBreakpoint): boolean {
    return this.breakpointObserver.isMatched(breakpoint);
  }

  observeBreakpoints(breakpoints: ScreenBreakpoint[]): Observable<any> {
    return this.breakpointObserver.observe(breakpoints);
  }

  observeSmallBreakpoint(): Observable<any> {
    return this.observeBreakpoints([ScreenBreakpoint.SM])
    .pipe(map((result: BreakpointState) => {
      const isSmall = result?.breakpoints?.[ScreenBreakpoint.SM] || false;
      return isSmall;
    }));
  }

  observeMediumBreakpoint(): Observable<any> {
    return this.observeBreakpoints([ScreenBreakpoint.MD])
    .pipe(map((result: BreakpointState) => {
      const isMedium = result?.breakpoints?.[ScreenBreakpoint.MD] || false;
      return isMedium;
    }));
  }
}
