import { NgModule } from '@angular/core';

import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { FormInputTextAreaNdComponent } from './nd-form-input-text-area/nd-form-input-text-area.component';
import { FormInputCheckBoxNdComponent } from './nd-form-input-checkbox/nd-form-input-checkbox.component';
import { FormInputTextNdComponent } from './nd-form-input-text/nd-form-input-text.component';
import { FormInputRadioNdComponent } from './nd-form-input-radio/nd-form-input-radio.component';
import { FormInputDropdownNdComponent } from './nd-form-input-dropdown/nd-form-input-dropdown.component';
import { TablePaginationComponent } from './nd-pagination/nd-table-pagination.component';
import { FormInputDateNdComponent } from './nd-form-input-date/nd-form-input-date.component';
import { FormInputDateRangeNdComponent } from './nd-form-input-date-range/nd-form-input-date-range.component';
import { FormInputMonthYearNdComponent } from './nd-form-input-month-year/nd-form-input-month-year.component';
import { FormInputDateTimeNdComponent } from './nd-form-input-date-time/nd-form-input-date-time.component';
import { FormInputTimePickerNdComponent } from './nd-form-input-time-picker/nd-form-input-time-picker.component';
import { RichTextComponent } from './nd-rich-text/rich-text.component'
import { FormInputSlideToggleNdComponent } from './nd-form-input-slide-toggle/nd-form-input-slide-toggle.component';


const moduleComponents = [
  FormInputTextNdComponent,
  ValidationMessageComponent,
  FormInputTextAreaNdComponent,
  FormInputCheckBoxNdComponent,
  FormInputRadioNdComponent,
  FormInputDropdownNdComponent,
  TablePaginationComponent,
  FormInputDateNdComponent,
  FormInputDateRangeNdComponent,
  FormInputMonthYearNdComponent,
  FormInputDateTimeNdComponent,
  FormInputTimePickerNdComponent,
  FormInputSlideToggleNdComponent,
  RichTextComponent
];
@NgModule({
  imports: [...moduleComponents],
  bootstrap: [],
  declarations: [],
  exports: [...moduleComponents],
  providers: [],
  schemas: [],
})
export class FormControlsNdModule {}
