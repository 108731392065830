import { TitleCasePipe } from '@angular/common';
import { Component, OnInit,input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
  standalone: true,
  imports:[TranslateModule,TitleCasePipe]
})
export class StatusComponent implements OnInit {
  // Possible values are: active, inactive, draft, deleted, open, closed.
  // Every value will be presented in a different color
  // (any new value should be added to style.sccs as .c4p-status-icon-newvalue)
  value = input<string>('');

  constructor() {}

  ngOnInit(): void {}
}
