import { DestroyRef, EnvironmentInjector, inject, Injectable, runInInjectionContext, signal } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BreadcrumbModel } from '../breadcrumb-standalone/breadcrumb-st.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BREADCRUMB_DETAIL_TEXT_MAP } from '../../utilities';



@Injectable({
  providedIn: 'root',
})
export class BreadcrumbStService {
  breadcrumbs = signal<BreadcrumbModel[]>([]);

  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {
    this.initializeBreadcrumbs();
  }

  private initializeBreadcrumbs(): void {
    // Listen for future NavigationEnd events
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateBreadcrumbs();
      });

    // Handle the initial navigation state (e.g., browser refresh)
    this.updateBreadcrumbs();
  }

  private updateBreadcrumbs(): void {

    this.breadcrumbs.set([]);
    const breadcrumbs = this.createBreadcrumbs(this.activatedRoute.root);
    this.breadcrumbs.set(breadcrumbs);
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: BreadcrumbModel[] = []
  ): BreadcrumbModel[] {

    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      
      const label = child.snapshot?.data?.['breadcrumbLabel'];
      const loading = child.snapshot?.data?.['loading'];
      // Avoid adding duplicate breadcrumbs
      if (!breadcrumbs.some((b) => b.label === label && b.url === url)) {
        breadcrumbs.push({ label, url,loading });
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
    return breadcrumbs;
  }

  updateLastBreadcrumb(label: string): void {
    const currentBreadcrumbs = this.breadcrumbs();
    const updatedBreadcrumbs = [
      ...currentBreadcrumbs.slice(0, -1),
      { label, url: currentBreadcrumbs[currentBreadcrumbs.length - 1]?.url },
    ];
    this.breadcrumbs.set(updatedBreadcrumbs);
  }

  addBreadCrumb(breadcrumb: BreadcrumbModel): void {
    this.breadcrumbs.update(breadcrumbs => [...breadcrumbs, breadcrumb]);
  }

  clearBreadCrumbs(): void {
    this.breadcrumbs.set([]);
  }

  deleteBreadCrumb(label: string): void {
    this.breadcrumbs.update(breadcrumbs => breadcrumbs.filter(breadcrumb => breadcrumb.label !== label));
  }

  updateBreadCrumbWithLabel(oldLabel: string, newLabel: string,loading:boolean=false,newUrl?:string,skipNumber?:number,navigateUrl?:string): void {
    this.breadcrumbs.update(breadcrumbs =>
      breadcrumbs.map(breadcrumb =>
        breadcrumb.label === oldLabel ? { ...breadcrumb, label: newLabel,loading,url: newUrl ?? breadcrumb.url,skipNumber:skipNumber ?? 0,navigateUrl:navigateUrl } : breadcrumb
      )
    );
  }

  setBreadCrumbs(breadcrumbs: BreadcrumbModel[]): void {
    this.breadcrumbs.set(breadcrumbs);
  }

  setBreadCrumb(route: ActivatedRouteSnapshot, label: string): void {
      if (label) {
        const breadcrumbLabel = label;
        route.data = { ...route.data, breadcrumbLabel };
      }
  }

  breadcrumbFunction = (environmentInjector:EnvironmentInjector,destroyRef:DestroyRef,name:string,detailText:string,skipNumber?:number,navigateUrl?:string) => {
    const breadcrumbLabels =BREADCRUMB_DETAIL_TEXT_MAP[detailText]
    runInInjectionContext(environmentInjector, () => {
  
      const router = inject(Router);
      this.updateBreadCrumbWithLabel(detailText,name,false,null,skipNumber,navigateUrl);
      router.events
        .pipe(
          filter((event): event is NavigationEnd => event instanceof NavigationEnd),
          takeUntilDestroyed(destroyRef) 
        )
        .subscribe((event: NavigationEnd) => {
          const segment = Object.keys(breadcrumbLabels).find(key => event.url.includes(key));
          if (segment) {
            this.updateBreadCrumbWithLabel(breadcrumbLabels[segment], name,false,null,skipNumber,navigateUrl);
          }
        });
    });
  };
}