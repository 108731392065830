import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateMaskDirective } from './date-mask.directive';
import { TopBarDirective } from './top-bar.directive';
import { TippyDirective } from './tippy.directive';
import { DragDropDirective } from './drag-drop.directive';
import { ClickStopPropagation } from './click-stop-propagation.directive';
import { HasPermissionDirective } from './has-permission.directive';


const moduleComponents = [
  DateMaskDirective,
  TopBarDirective,
  TippyDirective,
  DragDropDirective,
  ClickStopPropagation,
  HasPermissionDirective
];
@NgModule({
  imports: [CommonModule],
  declarations: [...moduleComponents],
  exports: [...moduleComponents],
  providers: [],
})
export class DirectivesModule {}
