<div class="container p-2 sidesheet-commandline-container" [ngStyle]="sideSheetConfig?.style?.commandLine">
    @if (commandLineTemplate) {
        <ng-container *ngTemplateOutlet="commandLineTemplate"></ng-container>
    } @else {
        <div class="row gap-2">
            <ng-container *ngTemplateOutlet="sideSheetCommandLineDefaultTemplate"></ng-container>
        </div>
    }
</div>


<ng-template #sideSheetCommandLineDefaultTemplate>
    <ng-container *ngFor="let buttonItem of sideSheetContent?.buttons; let index = index">
        <ng-container *ngIf="buttonItem.visible">
            <ng-container
                *ngTemplateOutlet="sideSheetCommandLineButton;context:{buttonItem: buttonItem,index:index}"></ng-container>
        </ng-container>
    </ng-container>
</ng-template>

<ng-template #sideSheetCommandLineButton let-item='buttonItem'>
    <button class="col-sm m-0 m-sm-1" [ngClass]="item?.classes?.join(' ')" [ngStyle]="item?.style"
            [disabled]="item.disabled || item?.loading" (click)="onCommanlineButtonClicked(item)" [attr.disabled]="(item.disabled || item?.loading) ? '' : null">
      {{ item.name | translate }}
      <ng-container *ngIf="item?.loading">
        <mat-spinner color="primary" diameter="15" class="custom-spinner"></mat-spinner>
      </ng-container>
    </button>

</ng-template>
