import { Component, contentChildren, effect, input, OnDestroy, OnInit } from '@angular/core';
import { MatMenuModule } from "@angular/material/menu"
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgTemplateOutlet } from '@angular/common';
import { ResponsiveService } from 'tuula-common';
import { Subject, takeUntil } from 'rxjs';
import { CollapsableButtonComponent } from '../collapsable-button/collapsable-button.component';



@Component({
  selector: 'app-collapsable-button-container',
  templateUrl: './collapsable-button-container.component.html',
  styleUrl: './collapsable-button-container.component.scss',
  standalone:true,
  imports: [MatMenuModule,MatIconModule,NgFor,NgTemplateOutlet]
})
export class CollapsableButtonContainerComponent implements OnInit, OnDestroy {

  actionButtons = contentChildren(CollapsableButtonComponent);  
  private readonly destroyed = new Subject<boolean>();

  numberOfVisibleButtons = input<number>(3);
  numberOfVisibleButtonsOnSmallDevices = input<number>(0);
  moreButtonCssClass = input<string>('btn-round-icon-borderless');

  visibleButtons: CollapsableButtonComponent[] = [];
  overflowButtons: CollapsableButtonComponent[] = [];

  mobileView: false;

  constructor(private responsiveService: ResponsiveService) { 
    effect(() => {
      if (this.actionButtons()?.length > 0) {
        this.manageButtonsVisibility();
      }
    });
  }

  ngOnInit(): void {
    this.responsiveService.observeSmallBreakpoint()
    .pipe(takeUntil(this.destroyed))
    .subscribe((result) => {
      this.mobileView = result;
      this.manageButtonsVisibility();
    });
  }

  ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  private manageButtonsVisibility() {
    if (this.actionButtons()?.length > 0) {
      const visibleCount = this.mobileView ? this.numberOfVisibleButtonsOnSmallDevices() : this.numberOfVisibleButtons();
      this.visibleButtons = this.actionButtons().slice(0, visibleCount);
      this.overflowButtons = this.actionButtons().slice(visibleCount);
      this.visibleButtons?.forEach(button => button.setOverflow(false));
      this.overflowButtons?.forEach(button => button.setOverflow(true));
    }
  }

}
