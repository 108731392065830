import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { CAREPROGRAM_STATUSES } from '../../../const';
import { CareProgramService } from '../../../services/care-program.service';
import { ClientOverviewService } from '../../../services/client-overview.service';
import { CarefileServiceService } from '../../../services/carefile-service.service';
import { startWith, takeUntil } from 'rxjs/operators';
import { ClientInfoService, SettingTypeService } from '../../../../../services';
import { ActivatedRoute } from '@angular/router';
import { FundingStreamService } from '../../../../../services/referent/care-program/funding-stream/funding-stream.service';
import { CarefileServiceModel } from '../../../models/carefile-service.model';
import * as moment from 'moment';
import { CareFileModel } from '../../../../../models/carefile/carefile.model';
import { TenantDatePipe } from '../../../../../pipes';
import { DateUtils } from '../../../../../utilities';
import { BreadcrumbStService } from 'c4p-portal-util';

@Component({
  selector: 'app-care-program-section',
  templateUrl: './care-program-section.component.html',
  styleUrls: ['./care-program-section.component.scss'],
})
export class CareProgramSectionComponent implements OnInit, OnDestroy {
  breadcrumbService = inject(BreadcrumbStService);
  statuses = CAREPROGRAM_STATUSES;
  selectedCareForm$: Observable<any>;
  private readonly destroyed$ = new Subject<boolean>();
  clientFilters = null;
  employeeFilters = {};
  carefile: CareFileModel;
  carefileId: string;
  filterObjectService = null;
  isCarefileCareProgram: boolean;
  selectedValue = '1';
  restitutionMaxStartDate = null;
  restutionMinEndDate = null;
  endDateMaxDate = null;
  clientModel: any;
  dayOffset = 24 * 60 * 60 * 1000;

  public get careprogramForm(): FormGroup {
    return this.careprogramService.careProgramForm;
  }

  public get servicesFormControl(): FormControl {
    return this.careprogramForm.get('services') as FormControl;
  }

  public get endDateFormControl(): FormControl {
    return this.careprogramForm.get('endDate') as FormControl;
  }

  public get clientControl() {
    return this.careprogramForm.get('client') as FormControl;
  }

  constructor(
    public careprogramService: CareProgramService,
    public settingTypeService: SettingTypeService,
    public clientService: ClientOverviewService,
    public clientInfoService: ClientInfoService,
    private route: ActivatedRoute,
    public carefileServiceService: CarefileServiceService,
    public fundingStream: FundingStreamService,
    private cdr: ChangeDetectorRef,
    private tenantDatePipe: TenantDatePipe,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.updateBreadCrumbWithLabel('general.menu.Carefiles','general.menu.Carefiles',false,'/carefiles');
    this.isCarefileCareProgram = this.route.snapshot.data.isCarefileCareProgram;
    this.initServicesSubscription();

    this.initClientSubscription();
    this.getCarefileDetails(this.careprogramForm.value?.client?.id, true);

    this.careprogramForm
      .get('fundingStream')
      .setValue(this.selectedValue, { emitEvent: false });

    this.initRestitutionStartEndDateSubscription();
    this.initRegistrationDateSubscription();
    this.endDateMaxDate = this.findLatestCarefileServiceEndDate(
      this.servicesFormControl.value,
    );
    // If the careprogram already has a client on-load, disallow changing it
    if (this.careprogramForm.value?.client?.id) {
      this.clientControl.disable();
    }
  }


  fetchClientDetailsAndCheckInsurance(id: string){
    return this.clientInfoService.getClientDetail(id).subscribe((client:any) => {
        this.clientModel = client;
        this.checkValidInsurances(client);
    })
  }

  initClientSubscription() {
    this.careprogramForm
      .get('client')
      .valueChanges.pipe( takeUntil(this.destroyed$))
      .subscribe((client) => {
        this.careprogramForm.get('services').setValue(null);
        this.clientService.selectedCarefileId$.next({
          carefileId: null,
          initValue: true,
        });
        this.filterObjectService = {
          carefileId: null,
          status: 'open',
          date: this.registartionDate,
        };
        this.cdr.detectChanges();
        if (!client) return;
        this.getCarefileDetails(client.id);
        this.fetchClientDetailsAndCheckInsurance(client.id)
      });
  }

  initRegistrationDateSubscription() {
    this.careprogramForm
      .get('registrationDate')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((newDate) => {
        if (!newDate) {
          const createdAtDate = this.careprogramForm.get('createdAt').value;
          const createdAtDay = this.tenantDatePipe.transform(createdAtDate);
          this.careprogramForm.get('registrationDate').setValue(createdAtDay);
        }

        this.filterObjectService = {
          carefileId: this.carefileId ? this.carefileId : null,
          status: 'open',
          date: this.registartionDate,
        };
      });
  }

  initRestitutionStartEndDateSubscription() {
    if (this.careprogramForm.get('restitutionEndDate').value) {
      this.restitutionMaxStartDate = new Date(
        new Date(
          this.careprogramForm.get('restitutionEndDate').value,
        ).getTime() - this.dayOffset,
      );
    } else {
      this.restitutionMaxStartDate = null;
    }
    this.careprogramForm
      .get('restitutionStartDate')
      .valueChanges.pipe(
        startWith(this.careprogramForm.get('restitutionStartDate').value),
        takeUntil(this.destroyed$),
      )
      .subscribe((restitutionStartDateValue) => {
        if (restitutionStartDateValue) {
          this.restutionMinEndDate = new Date(
            new Date(restitutionStartDateValue).getTime() + this.dayOffset,
          );
        } else {
          this.restutionMinEndDate = null;
        }
      });
    this.careprogramForm
      .get('restitutionEndDate')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe((restitutionEndDateValue) => {
        if (restitutionEndDateValue) {
          this.restitutionMaxStartDate = new Date(
            new Date(restitutionEndDateValue).getTime() - this.dayOffset,
          );
        } else {
          this.restitutionMaxStartDate = null;
        }
      });
  }

  getCarefileDetails(clientId, initValue = false) {
    if (!clientId) return;
    this.clientService
      .getCarefileDetail(clientId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        if (!res) return;
        this.carefileId = res.id;
        this.carefile = res;
        this.clientService.selectedCarefileId$.next({
          carefileId: this.carefileId,
          initValue,
        });
        this.filterObjectService = {
          carefileId: this.carefileId ? this.carefileId : null,
          status: 'open',
          date: this.registartionDate,
        };
      });
  }

  private initServicesSubscription() {
    this.servicesFormControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((services: CarefileServiceModel[]) => {
        if (services && services.length > 0) {
          const latestSvcDate = this.findLatestCarefileServiceEndDate(services);
          if (latestSvcDate) {
            this.endDateMaxDate = latestSvcDate;
            this.endDateFormControl.patchValue(this.endDateMaxDate);
          }
        }
      });
  }

  private findLatestCarefileServiceEndDate(
    services: CarefileServiceModel[],
  ): Date | null {
    return services
      .map(({ endDate }) => endDate)
      .reduce((acc, curr) => {
        if (!acc && curr) acc = curr;
        else if (curr) {
          if (new Date(acc).getTime() < new Date(curr).getTime()) acc = curr;
        }
        return acc;
      }, null);
  }

  private checkValidInsurances(client: any) {
    const validInsurance = client.insurances?.find((insurance) => {
      const currentDate = DateUtils.getCurrentTenantDayMoment();
      const validInsuranceStartDate = moment(
        insurance.startDate,
      ).isSameOrBefore(currentDate);
      const validInsuranceEndDate = !!insurance.endDate
        ? moment(insurance.endDate).isSameOrAfter(currentDate)
        : true;
      if (validInsuranceStartDate && validInsuranceEndDate) {
        return insurance;
      } else {
        return null;
      }
    });
    if (!validInsurance) {
      this.careprogramForm
        .get('restitutionStartDate')
        .setValue(
          moment(this.careprogramForm.get('createdAt').value).startOf('day'),
        );
    }
  }

  private get registartionDate() {
    const careprogram = this.careprogramService.selectedCareProgram$.value;
    if (careprogram) return careprogram.registrationDate;
    return null;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public getMaxDate(date?: moment.Moment): moment.Moment | null {
    if (date) return moment(date).clone().subtract(1, 'days');
  }

  public getMinDate(date?: moment.Moment): moment.Moment | null {
    if (date) return moment(date).clone().add(1, 'days');
  }
}
