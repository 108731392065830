import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { TableColumn } from 'tuula-common';

@Directive({
  standalone: true,
  selector: '[appResizableColumn]',
})
export class ResizableColumnDirective {
  private startX!: number;
  private startWidth!: number;
  private originalDraggable!: boolean;
  private originalSortable!: boolean;

  isResizing!: boolean;
  private headerCellElement: HTMLElement | null = null;

  @Input() column: TableColumn;
  @Output() columnWidthChanged = new EventEmitter<{ prop: string; width: number }>();

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    const resizable = event.target as HTMLElement;
    if (resizable.classList.contains('resizable')) {
      this.originalDraggable = this.column.draggable;
      this.originalSortable = this.column.sortable;
      this.column.draggable = false;
      this.column.sortable = false;
      event.stopPropagation();
      event.preventDefault();

      this.isResizing = true;

      this.headerCellElement = this.el.nativeElement.closest('mat-header-cell');
      this.startX = event.pageX;
      this.startWidth = this.headerCellElement.offsetWidth;

      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    }
  }

  onMouseMove = (event: MouseEvent) => {
    if (!this.isResizing) return;

    const newWidth = this.startWidth + (event.pageX - this.startX);

    if (newWidth < 70) return;

    this.renderer.setStyle(this.headerCellElement, 'flex-basis', `${newWidth}px`);

    this.columnWidthChanged.emit({ prop: this.column.prop, width: newWidth });
  };

  onMouseUp = () => {
    this.isResizing = false;
    this.column.draggable = this.originalDraggable;
    this.column.sortable = this.originalSortable;

    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
  };
}
