<ng-template #actionTemplate>
  @if (!overflow) {
  <button class="{{cssClass()}}" [disabled]="disabled()" (click)="handleClick()"  
    matBadge="{{badge()}}" [matBadgeHidden]="badge()===null" matBadgeSize="small">
    @if (iconFullName()) {
      <i [tooltip]="name() | translate" placement="bottom" tooltipDelay="100" class="{{iconFullName()}}"></i>
    }
    <span>{{name() | translate}}</span>
  </button>
  } @else {
  <div class="tuula-menu-item" [attr.disabled]="disabled()" (click)="handleClick()">
    @if (iconFullName()) {
      <i class="{{iconFullName()}}" 
      matBadge="{{badge()}}" 
      [matBadgeHidden]="badge()===null" 
      matBadgeSize="small"></i>
    }
    <span class="tuula-menu-item-text">
      {{name() | translate}}
    </span>
  </div>
  }
</ng-template>
