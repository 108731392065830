import { NgModule } from "@angular/core";
import {
  SideSheetComponent,
  TooltipComponent,
  TuulaTableComponent,
  TuulaTableFilterHeaderColumnComponent,
  TuulaTableSettingsComponent,
  TuulaTableSkeletonComponent,
} from 'tuula-common';
import { CollapsableButtonContainerComponent } from "./collapsable-button-container/collapsable-button-container.component";
import { CollapsableButtonComponent } from "./collapsable-button/collapsable-button.component";

const moduleComponents = [
    TuulaTableComponent,
    SideSheetComponent,
    TuulaTableFilterHeaderColumnComponent,
    TuulaTableSettingsComponent,
    TuulaTableSkeletonComponent,
    TooltipComponent,
    CollapsableButtonContainerComponent,
    CollapsableButtonComponent
]

@NgModule({
  imports: [...moduleComponents],
  bootstrap: [],
  declarations: [],
  exports: [...moduleComponents],
  providers: [],
  schemas: [],
})
export class ComponentsModule {}
