@if (!data?.requireReason && data.method === 'DELETE') {
  <app-dialog-delete
    [title]="data?.title"
    [message]="data?.detail[this.selectedLanguage]?.checklist"
    [label]="data?.detail[this.selectedLanguage]?.confirmationText"
    (confirm)="onConfirm($event)"
    (dismiss)="onDismiss()"
    [isRequireConfirmation]="data?.requireConfirmation"
  >
  </app-dialog-delete>
} @else () {

  <div class="gatekeeper-container container" [formGroup]="gatekeeperForm">
    <div class="row gk-popup-header">
      <div class="col-11">
        <div class="gk-warning-icon">
          <div class="line-container">
            @for (line of lines; track line) {
              <div class="line-{{line}}"></div>
            }
          </div>
          <i class="ai-icon-alert-triangle ai-icon-2x"></i>
        </div>
      </div>
      <div class="col-1 gk-close-icon" (click)="onDismiss()">
        <i class="ai-icon-x-close ai-icon-2x"></i>
      </div>
     </div>

    <!-- gatekeeper title -->
    @if (data?.title) {
      <div class="row gk-row">
        <div class="col popup-title">
          <span translate>{{ data?.title }}</span>
        </div>
      </div>
    }

    @if (data?.detail[this.selectedLanguage]?.checklist) {
      <div class="row gk-row">
        <div
          class="col popup-content-top"
          [innerHTML]="data?.detail[this.selectedLanguage]?.checklist"
        ></div>
      </div>
    }

    <!-- gate keeper reason -->
    <div *ngIf="data?.requireReason" class="popup-content-top">
        <div class="row gk-row">
          <div class="col">
            <p>
              <span translate style="color: var(--gray-color-600)">{{
                data?.detail[this.selectedLanguage]?.description
              }}</span>
            </p>
          </div>
        </div>


        <div class="row gk-row popup-content-bottom">
          <div class="col">
            <span translate>general.labels.MeaningfulChanges</span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <app-nd-form-input-text-area
            [testId]="'gatekeeper-text-area'"
            controlName="reason"
            rows="7"
            style="height: 150px">
          </app-nd-form-input-text-area>
          </div>
        </div>
    </div>


    <!-- gatekeeper checklist -->
    @if (data?.requireConfirmation) {
      <div class="row gk-row" [ngClass]="gatekeeperForm?.get('isAgreed')?.value ? 'gk-toggle-agreed' : 'gk-toggle'" >
        <div class="col">
          <app-nd-form-input-slide-toggle
            [testId]="'gatekeeper-slide-toggle'"
            [titleVisible]="false"
            controlName="isAgreed"
            label="{{ data?.detail[this.selectedLanguage]?.confirmationText }}"
          ></app-nd-form-input-slide-toggle>
        </div>
      </div>
    }


    <!-- gatekeeper buttons -->
     <div class="row">
      <div class="col-sm">
        <div class="btn-light" (click)="onDismiss()">
            {{ 'general.labels.Cancel' | translate}}
        </div>
      </div>
      <div class="col-sm"  *ngIf="canShowConfirmLabel()">
        <div class="btn-primary" (click)="onConfirm()">
            {{ (data?.title || 'general.labels.Confirm') | translate}}
        </div>
      </div>

     </div>

  </div>
}


