import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'truncateHtmlPlainText' ,standalone: true})
export class TruncateHtmlPlainTextPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string, maxLength: number = 160): SafeHtml {
    if (!html) return '';
    const truncatedHtml = this.truncateHtml(html, maxLength);
    return this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(truncatedHtml)) ;
  }

  private truncateHtml(html: string, maxLength: number): string {
    const tokens = html.match(/<[^>]+>|[^<]+/g) || [];
    let currentLength = 0;
    const stack: string[] = [];
    const result: string[] = [];
    let truncated = false;

    for (const token of tokens) {
      if (truncated) break;

      if (token.startsWith('<')) {
        result.push(token);

        if (token.startsWith('</')) {
          const tagName = this.getTagName(token);
          if (stack.length > 0 && stack[stack.length - 1] === tagName) {
            stack.pop();
          }
        } else if (token.endsWith('/>')) {
        } else {
          const tagName = this.getTagName(token);
          stack.push(tagName);
        }
      } else {
        const text = token;
        const available = maxLength - currentLength;

        if (available <= 0) {
          truncated = true;
          break;
        }

        if (text.length <= available) {
          result.push(text);
          currentLength += text.length;
          if (currentLength === maxLength) {
            truncated = true;
          }
        } else {
          if (available >= 3) {
            const take = available - 3;
            result.push(text.substring(0, take) + '...');
            currentLength += available;
          } else {
            result.push(text.substring(0, available));
            currentLength += available;
          }
          truncated = true;
        }
      }
    }

    if (truncated) {
      const remainingTags = [...stack].reverse();
      remainingTags.forEach(tag => {
        result.push(`</${tag}>`);
      });
    }

    return result.join('');
  }

  private getTagName(tag: string): string {
    const match = tag.match(/^<\/?([^\s>/]+)/);
    return match ? match[1].toLowerCase() : '';
  }
}