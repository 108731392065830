import {
  Component,
  EventEmitter,
  Host,
  Input,
  OnInit,
  Optional,
  Output,
  signal,
  SkipSelf,
  ViewEncapsulation,
  input,
} from '@angular/core';
import {
  DATE_MONTH_YEAR_REGEX,
  DEFAULT_MAX_DATE,
  DEFAULT_MIN_DATE,
  YEAR_MONTH_FORMATS_ND,
} from '../../constants/form-controls.const';
import {
  AbstractControl,
  ControlContainer,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import {
  MatDatepicker,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Moment } from 'moment';
import { DateUtils } from '../../utilities';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';
import { DEFAULT_DATE_FORMAT } from '../../pipes/pipes.const';
import * as moment from 'moment';

@Component({
  selector: 'app-form-input-month-year-nd',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatDatepickerModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    ValidationMessageComponent,
  ],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './nd-form-input-month-year.component.html',
  styleUrl: './nd-form-input-month-year.component.scss',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: YEAR_MONTH_FORMATS_ND }],
})
export class FormInputMonthYearNdComponent implements OnInit {
  readonly minDate = input(DEFAULT_MIN_DATE);
  readonly maxDate = input(DEFAULT_MAX_DATE);
  readonly label = input<string>('');
  readonly placeholder = input<string>('');
  readonly hint = input<string>('');
  readonly testId = input.required<string, string>({
    transform: (value: string) => {
      return 'app-form-input-date-' + value;
    },
  });
  readonly disabled = input<boolean>(false);
  readonly controlName = input<string>(undefined);
  @Input() control: AbstractControl | null;
  required = signal<boolean>(false);
  dateFilter = input<Function>((d: Date | null): boolean => true);

  @Output() datepickerOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() datepickerClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeDate: EventEmitter<void> = new EventEmitter<void>();

  dateMonthYearRegex = DATE_MONTH_YEAR_REGEX;
  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
  ) {}

  ngOnInit(): void {
    const controlName = this.controlName();
    if (!this.control && controlName) {
      this.control = (this.controlContainer?.control as FormGroup)?.get(
        controlName,
      );
    }
    if (this.control) {
      this.setDefaultValues();

      if (this.disabled()) {
        this.control.disable();
      }
    }
  }

  onDatepickerOpened(): void {
    this.datepickerOpened.emit();
  }

  onDatepickerClosed(): void {
    this.datepickerClosed.emit();
  }
  /** When the user double clicks on the input field */ 
  setNow() {
    this.control?.setValue(DateUtils.getCurrentUTCDayMoment());
    this.changeDate.emit();
    this.datepickerClosed.emit();
  }
  /** When date is picked from the date picker or manually entered
   */
  onChangeDate() {
    this.changeDate.emit();
  }

  /** When date is entered manually
   */
  valueChangeHandler(event$: Event) {
    const inputValue = (event$?.target as HTMLInputElement).value;
    const stringDate = '01/' + inputValue;
    if (!stringDate) return;
    if (!DateUtils.isValidDate(stringDate)) {
      this.control?.setErrors({ matDatepickerParse: true });
    } else {
      const momentDate = DateUtils.format(stringDate, DEFAULT_DATE_FORMAT);
      this.control?.setValue(momentDate);
    }
  }

  get minDateValue() {
    return this.minDate() ?? DEFAULT_MIN_DATE;
  }

  get maxDateValue() {
    return this.maxDate() ?? DEFAULT_MAX_DATE;
  }
  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<any>,
  ) {
    const date = normalizedMonthAndYear;
    this.control?.setValue(date);
    datepicker.close();
  }

  setDefaultValues() {
    this.required.set(this.control?.hasValidator(Validators.required)!);
  }
}
