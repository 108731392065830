<mat-table [dataSource]="skeletonRows" class="skeleton-table">

  @for (column of columns(); track column) {
  <ng-container [matColumnDef]="column.prop">
    <mat-header-cell *matHeaderCellDef [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : defaultCellWidth(), 'justify-content': column.justify ? column.justify :'start' }"
    >
      @if (column.headerTemplate) {
        <ng-container
          *ngTemplateOutlet="column.headerTemplate; context: { column: column }">
        </ng-container>
      } @else {
        <span>{{( column.hideColumnName ? '' : column.name) | translate }}</span>
      }
    </mat-header-cell>
    <mat-cell *matCellDef="let row; let i = index"
              [ngStyle]="{ 'flex-grow': 1, 'flex-shrink': 0, 'flex-basis.px': column.width ? column.width : defaultCellWidth(), 'justify-content': column.justify ? column.justify :'start' }"
    >
    <div class="skeleton-cell">
      <div class="skeleton-progress"> </div>
    </div>

    </mat-cell>
  </ng-container>

  }

  <mat-header-row *matHeaderRowDef="tableDispayColumns" [ngStyle]="{ 'min-width.px': totalRowWidth() }"></mat-header-row>
  <mat-row *matRowDef="let row; columns: tableDispayColumns;"
  [ngStyle]="{ 'min-width.px': totalRowWidth() }"
  [ngClass]="[tableRowSize() === 'default'? 'tuula-table-row-default' : 'tuula-table-row-compact']"></mat-row>
</mat-table>
