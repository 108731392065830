<div class="sidesheet-overlay" *ngIf="sidesheetPopupRef"></div>
<div class="sidesheet-tooltip-container container" *ngIf="sidesheetPopupRef">

  @if (sidesheetPopupRef?.content?.confirmAction) {
    <ng-container
      *ngTemplateOutlet="confirmActionTemplate"></ng-container>
  } @else {
    <ng-container
      *ngTemplateOutlet="sidesheetPopupRef?.content?.template"></ng-container>
  }
</div>

<ng-template #confirmActionTemplate>

  <span [ngStyle]="sidesheetPopupRef?.content?.confirmAction?.contentStyle">
    <div class="sidesheet-tooltip-header d-flex justify-content-between" [ngStyle]="sidesheetPopupRef?.content?.confirmAction?.headerStyle">

        <div class="popup-warning-icon">
          <img src="/assets/svg/semi-circle.svg" class="semi-circle" alt="SVG Icon">
          <i class="{{sidesheetPopupRef?.content?.confirmAction?.iconClass ?? 'ai-icon-alert-triangle ai-icon-1-5x icon-red'}}"></i>
        </div>

      <button class="btn-round-icon-borderless" (click)="onClose()"><i class="ai-icon-x-close"></i></button>
    </div>
    
    <div class="h-12"></div>

    <div class="sidesheet-tooltip-header-text"> {{ sidesheetPopupRef?.content?.confirmAction?.textHeader | translate }}</div>
    
    <div class="sidesheet-tooltip-text">
      {{ sidesheetPopupRef?.content?.confirmAction?.textContent | translate }}
    </div>

    <div class="row m-b-2 mx-0">
      <button class="btn-light col-sm mx-0 mx-sm-2" *ngIf="sidesheetPopupRef?.content?.confirmAction?.textCancel" type="submit"
              (click)="onCancel()">
        {{sidesheetPopupRef?.content?.confirmAction?.textCancel | translate}}
      </button>
      <button class="btn-primary col-sm mx-0 mx-sm-2" type="submit" *ngIf="sidesheetPopupRef?.content?.confirmAction?.textOk"
              (click)="onOk()">
        <i class="ai-icon-check"></i>
        {{ sidesheetPopupRef?.content?.confirmAction?.textOk | translate }}
      </button>
    </div>
  </span>
</ng-template>
