<div class="c4p-form-questionset" style="padding: 2px" [ngStyle]="{'flex-direction' : isSideSheet ? 'column' : ''}">

    <app-tuula-table
      [tableKey]="'SocialArea'"
      [rows]="rows"
      [columns]="columns"
      [pagination]="pagination"
      [tableFilteringButtonVisible]="false"
      [tableSettingsVisible]="false"
      [showTopBar]="true"
      [tableLoading]="tableLoading"
      (changePage)="loadData($event)"
      (sortChange)="loadData($event)"
      (rowClicked)="onRowClick($event)"
      [tableControls]="tableControls"

    >
    </app-tuula-table>
  <ng-template #tableControls>
    <app-collapsable-button-container
      [moreButtonCssClass]="'btn-square-icon'">
      <app-collapsable-button
        cssClass="btn-primary"
        *ngIf="hasAddFull()"
        (click)="onFilterSocialAreas()"
        [icon]="'ai-icon-plus-circle'"
        [name]="'general.labels.Add'">
      </app-collapsable-button>
    </app-collapsable-button-container>
  </ng-template>
  <ng-template #redLineTemplate let-row="row">
  <span [ngClass]="{
    'black-text': firstChange,
    'red-text': row?.isInvalid && !firstChange
  }">
    {{ row?.name }}
  </span>
  </ng-template>

  <ng-template #tableRowActionsTemplate let-row='row'>
    <app-collapsable-button-container>
      <app-collapsable-button
        *ngIf="hasDeleteFull()"
        (click)="removeArea(row)"
        [icon]="'trash-01'"
        [name]="'general.actions.Delete'">
      </app-collapsable-button>
    </app-collapsable-button-container>
  </ng-template>

  <button
    mat-icon-button
    (click)="onToggle()"
    style="width: 0px"
    *ngIf="tabs.length > 0 && !isSideSheet"
  >
    <mat-icon
      class="c4p-top-icons"
      style="font-size: 15px; height: 20px; position: absolute; right: -34px; top: 57px; z-index: 10;"
    >menu</mat-icon
    >
  </button>

  <div class="c4p-form-questions-tab" *ngIf="tabs.length > 0">
    <mat-tab-group
      mat-stretch-tabs
      style="background-color: transparent; margin-top: 7px"
      [(selectedIndex)]="selectedIndex"
    >
      <mat-tab *ngFor="let tab of tabs; let index = index" [ngClass]="{'red-tab': tab.socialArea.isRed}">
        <ng-template mat-tab-label>
          <app-collaboration-users
            docIdentifier="{{
              'socialarea' +
                tab.socialArea.id +
                'entity' +
                entityId +
                entity +
                entityVersion
            }}"
          ></app-collaboration-users>
          <div class="col" style="margin-left: 20px">{{ tab.name }}</div>
          <button mat-icon-button (click)="closeTab($event, index)">
            <mat-icon style="font-size: 12px">close</mat-icon>
          </button>
        </ng-template>

        <app-goal
          [data]="tab.socialArea"
          [entityId]="entityId"
          [entity]="entity"
          [entityVersion]="entityVersion"
          [viewMode]="viewMode"
          [scoringEnabled]="scoringEnabled"
          [scoringUnanswered$]="scoringUnanswered$"
          (updateRequest)="updateArea($event)"
          [carefileId]="carefileId"
          [careplan]="careplan"
          [previewMode]="previewMode"
        ></app-goal>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
