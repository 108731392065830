import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../../../general-components/dialog/dialog.component';
import { CareTypeDeterminationDetailComponent } from '../../../components/care-type-determination-detail/care-type-determination-detail.component';
import {
  CareTypeDeterminationModel,
  CompleteCareProgramModel,
} from '../../../models';
import { CareProgramService } from '../../../services/care-program.service';
import { BreadcrumbStService } from 'c4p-portal-util';

@Component({
  selector: 'app-care-type-determination-section',
  templateUrl: './care-type-determination-section.component.html',
  styleUrls: ['./care-type-determination-section.component.scss'],
})
export class CareTypeDeterminationSectionComponent implements OnInit {
  breadcrumbService = inject(BreadcrumbStService);
  public get careprogramForm(): FormGroup {
    return this.careprogramService.careProgramForm;
  }

  public get careTypeDeterminationFormArray(): FormArray {
    return this.careprogramForm.get('careTypeDeterminationGGZ') as FormArray;
  }

  public displayedColumns = [
    'startDate',
    'endDate',
    'name',
    'code',
    'operations',
  ];

  constructor(
    private careprogramService: CareProgramService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.updateBreadCrumbWithLabel('general.menu.Carefiles','general.menu.Carefiles',false,'/carefiles');
    this.initCareTypeDeterminationData();
  }

  private initCareTypeDeterminationData() {
    const selectedCareProgram: CompleteCareProgramModel = this
      .careprogramService.selectedCareProgram$
      .value as CompleteCareProgramModel;
    if (
      selectedCareProgram &&
      selectedCareProgram.careTypeDeterminationGGZ?.length > 0 &&
      this.careTypeDeterminationFormArray.length === 0
    ) {
      selectedCareProgram.careTypeDeterminationGGZ.forEach(
        (careTypeDetermination) => {
          this.careTypeDeterminationFormArray.push(
            this.buildCareTypeDeterminationFormGroup(careTypeDetermination),
          );
        },
      );
    }
  }

  private buildCareTypeDeterminationFormGroup(
    careTypeDetermination: CareTypeDeterminationModel,
  ): FormGroup {
    const careTypeDeterminationFormGroup = this.formBuilder.group({
      identifier: [careTypeDetermination.identifier, Validators.required],
      GDS801Code: [careTypeDetermination.GDS801Code, Validators.required],
      startDate: [careTypeDetermination.startDate, Validators.required],
      endDate: [careTypeDetermination.endDate],
      code: [careTypeDetermination.code],
      name: [careTypeDetermination.name],
    });
    if (this.careTypeDeterminationFormArray.disabled)
      careTypeDeterminationFormGroup.disable();
    return careTypeDeterminationFormGroup;
  }

  public createCareTypeDetermination() {
    const dialogRef = this.dialog.open(CareTypeDeterminationDetailComponent, {
      data: { careTypeDetermination: null, viewOnly: false },
    });
    dialogRef
      .afterClosed()
      .subscribe((newCareTypeDetermination: CareTypeDeterminationModel) => {
        if (newCareTypeDetermination) {
          const careTypeDeterminationFormGroup =
            this.buildCareTypeDeterminationFormGroup(newCareTypeDetermination);
          this.careTypeDeterminationFormArray.push(
            careTypeDeterminationFormGroup,
          );
          careTypeDeterminationFormGroup.markAsDirty();
          this.careTypeDeterminationFormArray.markAsTouched();
        }
      });
  }

  public editCareTypeDetermination(
    careTypeDetermination: CareTypeDeterminationModel,
    index: number,
  ) {
    const dialogRef = this.dialog.open(CareTypeDeterminationDetailComponent, {
      data: { careTypeDetermination, viewOnly: false },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const careTypeDeterminationFormGroup =
          this.careTypeDeterminationFormArray.get([index]);
        careTypeDeterminationFormGroup.patchValue(result);
        careTypeDeterminationFormGroup.markAsDirty();
      }
    });
  }

  public viewCareTypeDetermination(index: number) {
    const careTypeDetermination = this.careTypeDeterminationFormArray.get([
      index,
    ]).value;
    const dialogRef = this.dialog.open(CareTypeDeterminationDetailComponent, {
      data: { careTypeDetermination, viewOnly: true },
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  public deleteCareTypeDetermination(index: number) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'general.messages.DeleteItem',
        message:
          'careprogram.messages.AreYouSureYouWantToCareTypeDeterminationGGZ',
        confirmText: 'general.actions.delete',
        method: 'DELETE',
      },
      panelClass: 'dialog-delete',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.careTypeDeterminationFormArray.markAsDirty();
        this.careTypeDeterminationFormArray.removeAt(index);
        this.careTypeDeterminationFormArray.markAsTouched();
      }
    });
  }
}
