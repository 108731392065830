import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PaginationDataModel } from '../../../models/pagination/pagination-data.model';
import { PaginationResultModel } from '../../../models/pagination/pagination-result.model';
import { DropdownServiceBase } from '../../../services/dropdown-service.abstract';
import { HttpUtils } from '../../../utilities/http.utils';
import {
  BasicCareProgramModel,
  CareProgramUtilizationModel,
  ClientCareProgramModel,
  CompleteCareProgramModel,
} from '../models';
import { CareProgramDropDownModel } from '../models/careprogram-drop-down.model';

@Injectable({
  providedIn: 'root',
})
export class CareProgramHttpService extends DropdownServiceBase<
  BasicCareProgramModel | CareProgramDropDownModel
> {
  public carePrograms$ = new BehaviorSubject<{
    data: BasicCareProgramModel[];
    count: number;
  }>(null);

  constructor(
    public http: HttpClient,
    @Inject('environment') private environment,
  ) {
    super();
    this.setupDropdown(this.getCareProgramsDropdown);
  }

  private careprogramUrl = this.environment.careprogramServiceApiUrl;

  public getCareProgramsDropdown(
    paginationData: PaginationDataModel,
    filterData?: any,
  ) {
    let headers: HttpHeaders = new HttpHeaders({
      origin_path: '/careprogram/drop-down',
    });

    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );

    return this.http.get<PaginationResultModel<CareProgramDropDownModel>>(
      `${this.careprogramUrl}/drop-down`,
      {
        headers,
        params,
      },
    );
  }

  getCarePrograms(
    paginationData: PaginationDataModel,
    requestFrom: string,
    filterData?: any,
  ): Observable<
    PaginationResultModel<BasicCareProgramModel | CompleteCareProgramModel>
  > {
    let headers: HttpHeaders = new HttpHeaders({
      origin_path: '/careprogram',
      entity_type: filterData.entityType,
    });
    if (requestFrom) headers = headers.set('request_from', requestFrom);
    delete filterData.entityType;
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );

    return this.http.get<
      PaginationResultModel<BasicCareProgramModel | CompleteCareProgramModel>
    >(`${this.careprogramUrl}`, {
      headers,
      params,
    });
  }

  createCareProgram(
    entityType: any,
    requestFrom: string,
    carefileId?: string,
  ): Observable<BasicCareProgramModel | CompleteCareProgramModel> {
    let headers: HttpHeaders = new HttpHeaders({
      origin_path: '/careprogram',
      entity_type: entityType,
    });
    if (requestFrom) headers = headers.set('request_from', requestFrom);
    entityType && headers.append('carefile_id', carefileId);

    return this.http.post<BasicCareProgramModel | CompleteCareProgramModel>(
      `${this.careprogramUrl}`,
      {},
      {
        headers,
      },
    );
  }

  getCareprogram(
    careProgramId: string,
    requestFrom: string,
    entityType: any,
  ): Observable<BasicCareProgramModel | CompleteCareProgramModel> {
    let headers: HttpHeaders = new HttpHeaders({
      origin_path: '/careprogram/:careprogram_id',
      entity_type: entityType,
    });
    if (requestFrom) headers = headers.set('request_from', requestFrom);

    return this.http.get<BasicCareProgramModel | CompleteCareProgramModel>(
      `${this.careprogramUrl}/${careProgramId}`,
      {
        headers,
      },
    );
  }

  searchClientsWhenNoCarePrograms(
    paginationData: PaginationDataModel,
    requestFrom: string,
    filterData?: any,
  ): Observable<PaginationResultModel<ClientCareProgramModel>> {
    let headers: HttpHeaders = new HttpHeaders({
      origin_path: '/careprogram/clients/personal-info',
      entity_type: filterData.entityType,
    });
    if (requestFrom) headers = headers.set('request_from', requestFrom);
    delete filterData.entityType;
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );

    return this.http.get<PaginationResultModel<ClientCareProgramModel>>(
      `${this.careprogramUrl}/clients/personal-info`,
      {
        headers,
        params,
      },
    );
  }

  createCareprogramFromFilters(
    client: ClientCareProgramModel,
  ): Observable<BasicCareProgramModel> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/careprogram',
    });

    return this.http.post<BasicCareProgramModel>(
      `${this.careprogramUrl}`,
      client,
      {
        headers,
      },
    );
  }

  public updateCareprogram(
    careprogram: BasicCareProgramModel | CompleteCareProgramModel,
    requestFrom: string,
    entityType: any,
  ): Observable<BasicCareProgramModel | CompleteCareProgramModel> {
    let headers: HttpHeaders = new HttpHeaders({
      origin_path: '/careprogram/:careprogram_id',
      entity_type: entityType,
    });
    if (requestFrom) headers = headers.set('request_from', requestFrom);

    return this.http.put<BasicCareProgramModel | CompleteCareProgramModel>(
      `${this.careprogramUrl}/${careprogram.id}`,
      careprogram,
      {
        headers,
      },
    );
  }

  public deleteCareprogram(
    careprogramId: string,
    requestFrom: string,
    entityType: any,
  ) {
    let headers: HttpHeaders = new HttpHeaders({
      origin_path: '/careprogram/:careprogram_id',
      entity_type: entityType,
    });
    if (requestFrom) headers = headers.set('request_from', requestFrom);

    return this.http.delete<any>(`${this.careprogramUrl}/${careprogramId}`, {
      headers,
    });
  }

  public getCareprogramUtilization(
    carefileId:string,
    requestFrom: string,
    entityType: string,
  ): Observable<CareProgramUtilizationModel> {
    let headers: HttpHeaders = new HttpHeaders({
      origin_path: '/careprogram',
      entity_type: entityType,
    });
    if (requestFrom) headers = headers.set('request_from', requestFrom);
    return this.http.get<CareProgramUtilizationModel>(
      `${this.careprogramUrl}/careprogram-utilization`,
      {
        headers,
        params:{
          status: 'active',
          carefileId:carefileId
        }
      },
    );
  }   
}

