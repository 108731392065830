import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MasterDropdownModel} from '../../../models/master-dropdown/master-dropdown.model';
import {MasterDataService} from '../../master-data/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class MasterService {

  private masterDataService = inject(MasterDataService)

  public fetchClientLanguages(): Observable<any[]> {
    return this.masterDataService.getMasterData('client-languages').pipe(map((data:any) => data));
  }

  public fetchGenders(): Observable<MasterDropdownModel[]> {
    return this.masterDataService.getMasterData('gender').pipe(map((data:any) => {
        return data.map((item:any) => new MasterDropdownModel(item))
      })
    );
  }

  public fetchJuridicalStatusValues(): Observable<any[]> {
    return this.masterDataService.getMasterData('juridical-status').pipe(map((data:any) => data));
  }

  public fetchCommunicationForms(): Observable<any[]> {
    return this.masterDataService.getMasterData('communication-forms').pipe(map((data:any) => data));
  }

  public fetchNameUseValues(): Observable<any[]> {
    return this.masterDataService.getMasterData('name-use').pipe(map((data:any) => data));
  }
  public fetchAddressTypes(): Observable<MasterDropdownModel[]> {
      return this.masterDataService.getMasterData('address-types').pipe(map((data:any) => {
          return data.map((item:any) => new MasterDropdownModel(item))
        })
      );
  }

  public fetchPhoneTypes(): Observable<MasterDropdownModel[]>  {
   return  this.masterDataService.getMasterData('phone-types').pipe(map((data:any) => {
        return data.map((item:any) => new MasterDropdownModel(item))
      })
    );
  }

  public fetchEmailTypes(): Observable<MasterDropdownModel[]> {
    return this.masterDataService.getMasterData('email-types').pipe(map((data:any) => {
        return data.map((item:any) => new MasterDropdownModel(item))
      })
    );
  }

  public fetchWorkTypes():  Observable<MasterDropdownModel[]> {
   return  this.masterDataService.getMasterData('work-types').pipe(map((data:any) => {
        return data.map((item:any) => new MasterDropdownModel(item))
      })
    );
  }

  public fetchCountries():  Observable<MasterDropdownModel[]> {
    return this.masterDataService.getMasterData('countries').pipe(map((data:any) => {
        return data.map((item:any) => new MasterDropdownModel(item))
      })
    );
  }

  public fetchDesignations():  Observable<MasterDropdownModel[]> {
    return this.masterDataService.getMasterData('designations').pipe(map((data:any) => {
        return data.map((item:any) => new MasterDropdownModel(item))
      })
    );
  }

  public fetchReferrerTypes():  Observable<MasterDropdownModel[]> {
    return this.masterDataService.getMasterData('referrer-types').pipe(map((data:any) => {
        return data.map((item:any) => new MasterDropdownModel(item))
      })
    );
  }
  public fetchRelations():  Observable<MasterDropdownModel[]> {
    return this.masterDataService.getMasterData('relation').pipe(map((data:any) => {
        return data.map((item:any) => new MasterDropdownModel(item))
      })
    );
  }

  public fetchEndCareReasons():  Observable<MasterDropdownModel[]> {
    return this.masterDataService.getMasterData('end-care-reason').pipe(map((data:any) => {
        return data.map((item:any) => new MasterDropdownModel(item))
      })
    );
  }

}
