<app-page-content class="app-document-overview">
  <app-panel [@slideFromTop] *ngIf="toggleFilters && !careprogramViewMode">
    <app-panel-title title="application.overview.Filters" titleIcon="search">
    </app-panel-title>

    <app-document-filters-form (submit)="filterParams = $event; refresh()">
    </app-document-filters-form>
  </app-panel>

  <app-panel *ngIf="toggleFilters && careprogramViewMode">
    <app-panel-title title="application.overview.Filters" titleIcon="search">
    </app-panel-title>

    <app-document-filters-form (submit)="filterParams = $event; refresh()">
    </app-document-filters-form>
  </app-panel>

  <app-panel>
    <app-table-view
      [showSearch]="!toggleFilters"
      [options]="tableOptions"
      (addRequest)="create()"
      (refreshRequest)="tableView = $event; refresh()"
    >
    </app-table-view>
  </app-panel>
</app-page-content>
