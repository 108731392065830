export class MasterDropdownModel {
  id?: string;
  name?: string;
  label?: string;
  value?: string;

  constructor(data: any= {}) {
    this.id = data?.id ? data?.id : data.value ?? null;
    this.name = data?.name ?? null;
    this.label = data?.label ?? null;
    this.value = data?.value ?? null;
  }
}
