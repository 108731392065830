<div style="display: flex">
  <span
    *ngIf="values.length == 0"
    class="object-list-text"
    (click)="selectItemHandler()"
  >
    -
  </span>

  <span
    *ngIf="values.length == 1"
    class="object-list-text"
    (click)="selectItemHandler()"
  >
    {{ values[0] | translate }}
  </span>

  <span mat-icon-button [matMenuTriggerFor]="menu" *ngIf="values.length > 1">
    <span *ngIf="values?.length > 1" class="c4p-object-list-multiple"
      >{{ 'general.labels.Multiple' | translate }} ({{ values.length }})</span
    >
  </span>

  <mat-menu #menu="matMenu" class="c4p-menu">
    <div *ngFor="let item of values">
      <div class="object-list-menu-item" (click)="selectItemHandler()">
        <span class="object-list-text" style="color: white">{{ item | translate }}</span>
      </div>
    </div>
  </mat-menu>
</div>
