import { Component, inject, input, model, output, signal, TemplateRef, Type } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActionType, Button, createButton, FormerSidesheet, FunctionCallPipe, SideSheetConfig, SideSheetContent, SideSheetService } from 'tuula-common';

@Component({
  selector: 'app-sidesheet-header',
  templateUrl: './sidesheet-header.component.html',
  styleUrls: ['./sidesheet-header.component.scss'],
  standalone: true,
  imports: [TranslateModule,FunctionCallPipe]
})
export class SideSheetHeaderComponent {
  sideSheetService = inject(SideSheetService);
  closeButton = createButton('', ActionType.SIDE_SHEET_CLOSE);
  backButton = createButton('', ActionType.SIDE_SHEET_BACK);


  sideSheetConfig = model<SideSheetConfig>(null);
  sideSheetContent = input<SideSheetContent>();
  formerSidesheets = input<FormerSidesheet[]>();
  buttonClicked = output<Button>();
  breadcrumbActionsTemplate = input<TemplateRef<any>>();
  expandClass = signal<"expand-05" | "minimize-02">("expand-05");


  toggleFullScreen() {
    this.expandClass.update((prev) => {
      return prev === "expand-05" ? "minimize-02" : "expand-05";
    });
    this.sideSheetConfig.update((prev) => {
      return {
        ...prev,
        fullscreen: !prev.fullscreen
      }
    });
  }

  onCloseButton() {
    this.buttonClicked.emit(this.closeButton);
  }

  onBackButton() {
    this.buttonClicked.emit(this.backButton);
  }

  loadComponent(component: Type<any>, content: SideSheetContent, config: SideSheetConfig): void {
    this.sideSheetService.loadComponent(component, content, config, false);
  }

  breakFirstBreadcrumb(breadcrumb:string){
      return breadcrumb?.split("|")
  }

}