<div class="custom-slide-toggle">
  
  @if (titleVisible) {
    <label class="custom-input-title">{{ title | translate }}</label>
  }

  <div class="slide-toggle-wrapper">
    <mat-slide-toggle [attr.testId]="testId" [labelPosition]="labelPosition" [formControl]="$any(control)">{{
      (label | translate)?.trim()
    }}@if(control?.hasValidator(requiredTrue)){<span class="required-field">*</span>}
    </mat-slide-toggle>
  </div>

  @if(control?.errors){
    <mat-error class="custom-input-error">
      <app-validation-message [errors]="control.errors"></app-validation-message>
    </mat-error>
  } @else if (hint){
    <mat-hint class="custom-input-hint" [align]="alignHint">{{ hint ?? '' }}</mat-hint>
  }
</div>
