export class CareProgramUtilizationModel {
    registrationDate: string; 
    zpmType:string; 
    id:string;
    totalWorkTime:string;
    billableWorkTime:string;
    nonBillableWorkTime:string;
    billableDiagnosticWorkTime:string;
    billableTreatmentWorkTime:string;
    
}