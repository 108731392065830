import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Inject, Injectable, signal} from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BackendServiceType, IFormDropdownOption } from '../../form-controls';
import { PaginationDataModel } from '../../models';
import { COLLECTION_NAMES, HttpUtils } from '../../utilities';
import { LanguageService } from '../language/language.service';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {

  masterDataMap= signal<any>(new Map());

  constructor(
    private http: HttpClient,
    private languageService: LanguageService,
    @Inject('environment') public environment: any,
  ) {}

  getMasterDataHttp(
    type: string
  ): Observable<any[]> {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/data',
      entity_type: 'MasterData',
    });

    if (this.masterDataMap().has(type)) {
      const originalResources = this.masterDataMap().get(type);
      return of(JSON.parse(JSON.stringify(originalResources)));
    } else {
      const params = new HttpParams().set('resources', type);
      return this.http
        .get(`${this.environment.masterdataApiUrl}/data`, {
          headers,
          params,
        })
        .pipe(
          map((data) => <any[]>data[type.replace(/-/g, '_')]),
          map((resources) => {
            const originalResources = resources?.length > 0 ? resources : [];
            this.masterDataMap().set(type, originalResources);
            return JSON.parse(JSON.stringify(originalResources));
          })
        )
    }
  }

  getMasterData(
    type: string,
    objectAsValue?: boolean,
  ): Observable<IFormDropdownOption[]> {
    return this.getMasterDataHttp(type)
      .pipe(
        map((resources) => {
          if (resources) {
            resources.map(
              (resource) =>
                (resource.label = this.languageService.translation(
                  resource.name,
                )),
            );
            resources.sort((a, b) => {
              if (a.sortOrder && b.sortOrder) {
                return a.sortOrder - b.sortOrder;
              } else {
                return a.label?.localeCompare(b.label);
              }
            });
            return resources.map((resource) => {
              let value = '';
              if (objectAsValue) value = resource;
              else if (resource.value) value = resource.value;
              else value = resource.code;
              return {
                label: resource.name,
                value,
                type: resource.type,
                percentage: resource.percentage,
                from: resource.from,
                to: resource.to,
              };
            });
          } else {
            return [];
          }
        }),
      );
  }

  getAutocompleteData(
    searchParams: any,
    service: BackendServiceType,
    urlSuffix: string,
    displayProperty: string,
    valueProperty: string,
  ): Observable<IFormDropdownOption[]> {
    let origin_path = '';
    let requestUrl = '';

    switch (service) {
      case 'clients':
        origin_path = '/clients';
        requestUrl = this.environment.clientManagementApiUrl;
        break;
      case 'tenants':
        origin_path = '/tenants';
        requestUrl = this.environment.tenantManagementApiUrl;
        break;
      case 'applications':
        origin_path = '/applications';
        requestUrl = this.environment.applicationManagementApiUrl;
        break;
      case 'client-network':
        origin_path = '/client-networks/autoselect';
        requestUrl = this.environment.clientNetworkManagementUrl;
        break;
      case 'employees':
        origin_path = '/employees/team/autoselect';
        requestUrl = this.environment.employeeManagementApiUrl;
        break;
      case 'teams':
        origin_path = '/teams';
        requestUrl = this.environment.teamManagementApiUrl;
        break;
      case 'contact-persons':
        origin_path = '/contacts/persons/:entity/:entity_id';
        requestUrl = `${this.environment.communicationManagementApiUrl}`;
        break;
      default:
        throw new Error('Invalid Service value ' + service);
    }
    if (urlSuffix) requestUrl += '/' + urlSuffix;

    const headers: HttpHeaders = new HttpHeaders({ origin_path });
    const params = HttpUtils.getPaginationAndFilterParams(
      new PaginationDataModel('name'),
      searchParams,
    );

    return this.http.get<any>(requestUrl, { params, headers }).pipe(
      map((data) => data.docs ?? data),
      map((results) => {
        return results.map((result: any) => {
          if (valueProperty === 'object')
            return { label: result[displayProperty], value: result };
          else if (valueProperty)
            return {
              label: result[displayProperty],
              value: result[valueProperty],
            };
          return { label: result[displayProperty], value: result };
        });
      }),
    );
  }

  public getContinueWithHelpOptions() {
    return this.getMasterData(COLLECTION_NAMES.CONTINUE_WITHOUT_HELP_OPTIONS);
  }

  public getStartedWithCrisisOptions() {
    return this.getMasterData(COLLECTION_NAMES.STARTED_WITH_CRISIS_OPTIONS);
  }

  public getTypeOfHelpOptions() {
    return this.getMasterData(COLLECTION_NAMES.TYPE_OF_HELP_OPTIONS);
  }
}
